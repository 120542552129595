import { useMemo, useState } from 'react';
import {
  FaCheckCircle,
  FaList,
  FaProjectDiagram,
  FaRegClock,
  FaRunning,
  FaUserPlus,
} from 'react-icons/fa';
import 'react-circular-progressbar/dist/styles.css';
import ProjectTile from 'src/components/project-tile/project-tile';
import ProgressSpinner from 'src/components/progress-spinner/progress-spinner';
import { SlOptions } from 'react-icons/sl';
import { AvatarStack } from 'src/components/ui/avatar-stack';
import SearchInput from 'src/components/search-input/search-input';
import { MultiSelect } from 'src/components/ui/multi-select';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { useUser } from 'src/hooks/useUser';

export const getProgressStateColor = (progress: number) => {
  if (progress < 40) return '#f56565';
  if (progress < 70) return '#ed8936';
  return '#48bb78';
};

const projectTypeList = [
  { value: 'Smart Doc', label: 'Smart Doc' },
  { value: 'Optimizer', label: 'Optimizer' },
  { value: 'Transformation', label: 'Transformation' },
  { value: 'Application Builder', label: 'Application Builder' },
];

const ProjectNavigationTabs = ({
  projects,
  projectInvites,
}: {
  projects: any;
  projectInvites: any;
}) => {
  const [activeTab, setActiveTab] = useState<string>('All');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedProjectType, setSelectedProjectType] = useState<string[]>([
    'Smart Doc',
  ]);
  const [selectedTimeLine, setSelectedTimeLine] = useState<string>('Ascending');
  const user = useUser();

  const navigate = useNavigate();

  const filteredProjects = useMemo(() => {
    if (activeTab === 'Project Type') {
      if (!selectedProjectType.length) return projects;
      return projects.filter((project: any) =>
        selectedProjectType.includes(project.type),
      );
    }

    if (activeTab === 'Timeline') {
      if (!selectedTimeLine) return projects;
      return [...projects].sort((a: any, b: any) => {
        if (selectedTimeLine === 'Ascending') {
          return Number(new Date(a.dueDate)) - Number(new Date(b.dueDate));
        } else if (selectedTimeLine === 'Descending') {
          return Number(new Date(b.dueDate)) - Number(new Date(a.dueDate));
        }
        return Number(new Date(a.dueDate)) - Number(new Date(b.dueDate));
      });
    }

    let result: any;
    switch (activeTab) {
      case 'Invites':
        result = projectInvites;
        break;

      case 'Active':
        result = projects.filter((project: any) => project.status === 'ACTIVE');
        break;

      case 'Completed':
        result = projects.filter((project: any) => project.progress === 100);
        break;

      case 'Archived':
        result = projects.filter(
          (project: any) => project.status === 'ARCHIVED',
        );
        break;

      default:
        result = projects;
    }

    if (searchValue) {
      return result.filter((project: any) =>
        project.title.toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else {
      return result;
    }
  }, [projects, activeTab, searchValue, selectedProjectType, selectedTimeLine]);

  let tabs = [
    { name: 'All', icon: <FaList /> },
    { name: 'Invites', icon: <FaUserPlus /> },
    { name: 'Active', icon: <FaRunning /> },
    { name: 'Project Type', icon: <FaProjectDiagram /> },
    { name: 'Timeline', icon: <FaRegClock /> },
    { name: 'Completed', icon: <FaCheckCircle /> },
    { name: 'Archived', icon: <FaList /> },
  ];

  if (user?.role === 'Admin') {
    tabs = tabs.filter(tab => tab.name !== 'Invites');
  }

  const handleInvitation = (project: any) => {
    alert('Invitation accepted for project id: ' + project.id);
  };

  const renderTabContent = () => {
    if (activeTab === 'Invites') {
      return filteredProjects.map((item: any) => (
        <ProjectTile
          key={item.id}
          topRightContent={
            <div>
              <AvatarStack
                maxAvatarsAmount={3}
                avatars={[
                  'https://github.com/shadcn.png',
                  'https://github.com/shadcn.png',
                  'https://github.com/shadcn.png',
                  'https://github.com/shadcn.png',
                ]}
              />
            </div>
          }
          actionCta={
            <button
              onClick={() => handleInvitation(item)}
              className="px-6 py-2 bg-blue-500 rounded-md text-white text-sm font-semibold"
            >
              Accept
            </button>
          }
          {...item}
        />
      ));
    }
    return filteredProjects.map((item: any) => (
      <ProjectTile
        key={item.id}
        topLeftIcon={
          <ProgressSpinner
            progress={item.progress}
            progressColor={getProgressStateColor(item.progress)}
          />
        }
        topRightContent={
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger className="focus:outline-none">
                <SlOptions />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>Project Settings</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => {
                    navigate(ROUTES.PROJECT_OVERVIEW);
                  }}
                >
                  View
                </DropdownMenuItem>
                <DropdownMenuItem>Archive</DropdownMenuItem>
                <DropdownMenuItem>Download Project Report</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        }
        actionCta={
          <AvatarStack
            maxAvatarsAmount={3}
            avatars={[
              'https://github.com/shadcn.png',
              'https://github.com/shadcn.png',
              'https://github.com/shadcn.png',
              'https://github.com/shadcn.png',
            ]}
          />
        }
        {...item}
      />
    ));
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">My Projects</h1>
      <div className="flex overflow-x-auto space-x-6 pb-6 border-b-2 border-gray-200">
        {tabs.map(tab => (
          <div
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            className={`cursor-pointer flex items-center space-x-4 py-3 px-6 rounded-full transition-all duration-300 ${
              activeTab === tab.name
                ? 'bg-blue-500 text-white shadow-lg scale-100'
                : 'text-gray-700 bg-blue-50 hover:text-blue-600'
            } focus:outline-none`}
          >
            <span className="text-xl">{tab.icon}</span>
            <span className="text-md font-semibold">{tab.name}</span>
          </div>
        ))}
      </div>
      <div className="py-2 mt-6 pt-1">
        {activeTab === 'Project Type' ? (
          <div className="max-w-md mx-auto">
            <MultiSelect
              options={projectTypeList}
              onValueChange={setSelectedProjectType}
              defaultValue={selectedProjectType}
              placeholder="Select Project Type"
              variant="inverted"
              animation={2}
              maxCount={3}
            />
          </div>
        ) : activeTab === 'Timeline' ? (
          <div className="mx-auto max-w-md">
            <Select
              value={selectedTimeLine}
              onValueChange={newValue => setSelectedTimeLine(newValue)}
            >
              <SelectTrigger className="min-h-14">
                <SelectValue placeholder="Select Timeline" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Ascending">Ascending</SelectItem>
                <SelectItem value="Descending">Descending</SelectItem>
              </SelectContent>
            </Select>
          </div>
        ) : (
          <SearchInput
            placeholder="Search by Project Name..."
            value={searchValue}
            onChange={setSearchValue}
          />
        )}
      </div>
      <div
        style={{
          minHeight:
            Math.max(projects.length, projectInvites.length) > 3
              ? '35rem'
              : '17rem',
        }}
        className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4"
      >
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ProjectNavigationTabs;
