import { useState } from 'react';
import Tabs from './header-navigation/header-navigation';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import { useGetGlobalSettingsQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';

const GlobalSettingsPage = () => {
  const [activeTab, setActiveTab] = useState('Smart Doc');
  const { error, isLoading } = useGetGlobalSettingsQuery();
  const [alertsShown, setAlertsShown] = useState({ globalSettings: false });

  if (error && !alertsShown.globalSettings) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load global settings. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, globalSettings: true });
  }

  return (
    <div className="flex flex-row bg-[#F3F4F4] min-h-[100vh]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'Settings'} />
      </div>
      <div className="mt-8 ml-8 w-full pb-8">
        <div>
          <h1 className="text-2xl font-bold">
            Default Transformation Settings
          </h1>
          <p className="mt-4 max-w-[80%]">
            Configure and customize default transformation settings for your
            projects. Adjust parameters and preferences to ensure accurate and
            efficient COBOL to Java conversion.
          </p>
          {isLoading ? (
            'Loading...'
          ) : (
            <div className="mt-8">
              <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalSettingsPage;
