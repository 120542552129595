import { useEffect, useState } from 'react';
import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Badge } from 'src/components/ui/badge';
import { FiExternalLink } from 'react-icons/fi';
import {
  fetchSupportTickets,
  ticketStatusToColorMap,
} from './support-ticket.service';

const SupportTicketsPage = () => {
  const [tickets, setTickets] = useState<any[]>([]);

  useEffect(() => {
    async function fetchTickets() {
      const response = await fetchSupportTickets();
      setTickets(response);
    }

    fetchTickets();
  }, []);

  return (
    <div className="p-4 flex flex-row bg-[#F3F4F4] min-h-[100vh]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <ProjectSideBar activeScreen={'Jira'} />
      </div>
      <div className="mt-4 ml-8">
        <div>
          <h1 className="text-2xl font-bold">Project Support Tickets</h1>
          <p className="mt-2">
            Manage the JIRA tickets linked to this project. Stay on top of
            issues, tasks, and progress as you work towards completing your
            COBOL to Java transformation.
          </p>
          <div
            className="p-8 mt-[2rem] rounded-md bg-white"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
          >
            <div>
              <Table>
                <TableCaption>
                  A list of support tickets linked to this project.
                </TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead>Ticket Number</TableHead>
                    <TableHead>Title</TableHead>
                    <TableHead className="min-w-[10rem]">Type</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead className="text-right" />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {tickets.map((ticket: any) => (
                    <TableRow key={ticket.id}>
                      <TableCell className="font-medium">{ticket.id}</TableCell>
                      <TableCell>{ticket.title}</TableCell>
                      <TableCell>{ticket.type}</TableCell>
                      <TableCell>
                        <Badge
                          style={{
                            background:
                              ticketStatusToColorMap[ticket.status]?.background,
                            color: ticketStatusToColorMap[ticket.status]?.color,
                          }}
                          variant="default"
                        >
                          {ticket.status}
                        </Badge>
                      </TableCell>
                      <TableCell className="text-right">
                        <button className="flex items-center justify-end ml-auto text-md text-blue-500 bg-transparent border border-blue-500 rounded-md py-2 px-4 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white hover:shadow-lg">
                          <FiExternalLink className="mr-2" />
                          Open in JIRA
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketsPage;
