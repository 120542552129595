import { useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { FaNoteSticky } from 'react-icons/fa6';
import { FiFileText, FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { GrLicense } from 'react-icons/gr';
import { IoCreateSharp } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';

const AdminSideBar = ({ activeScreen }) => {
  const [activeIcon, setActiveIcon] = useState(activeScreen);
  const navigate = useNavigate();

  const rotesMap = {
    Home: ROUTES.DASHBOARD,
    License: ROUTES.DASHBOARD,
    Reports: ROUTES.ADMIN_REPORTS,
    Notes: ROUTES.ADMIN_NOTES,
    Settings: ROUTES.ADMIN_GLOBAL_PROJECT_SETTINGS,
    ProjectCreation: ROUTES.DRAFT_PROJECTS,
    Users: ROUTES.USERS,
  };

  const iconClass = iconName =>
    iconName === activeIcon ? 'text-cta' : 'text-gray-500';

  const onClickItem = (screen: string) => {
    setActiveIcon(screen);
    if (rotesMap[screen]) navigate(rotesMap[screen]);
  };

  return (
    <div className="absolute bg-white w-8 mt-4 left-2 px-6 py-6 flex flex-col items-center gap-8 shadow-lg border rounded-md">
      <FaHome
        title="Home"
        size={24}
        className={`cursor-pointer ${iconClass('Home')}`}
        onClick={() => onClickItem('Home')}
      />
      <GrLicense
        title="License"
        size={24}
        className={`cursor-pointer ${iconClass('License')}`}
        onClick={() => onClickItem('License')}
      />
      <FiFileText
        title="Reports"
        size={24}
        className={`cursor-pointer ${iconClass('Reports')}`}
        onClick={() => onClickItem('Reports')}
      />
      <FaNoteSticky
        title="Notes"
        size={24}
        className={`cursor-pointer ${iconClass('Notes')}`}
        onClick={() => onClickItem('Notes')}
      />
      <FiSettings
        title="Settings"
        size={24}
        className={`cursor-pointer ${iconClass('Settings')}`}
        onClick={() => onClickItem('Settings')}
      />
      <IoCreateSharp
        title="ProjectCreation"
        size={24}
        className={`cursor-pointer ${iconClass('ProjectCreation')}`}
        onClick={() => onClickItem('ProjectCreation')}
      />
      <FiUsers
        title="Users"
        size={24}
        className={`cursor-pointer ${iconClass('Users')}`}
        onClick={() => onClickItem('Users')}
      />
    </div>
  );
};

export default AdminSideBar;
