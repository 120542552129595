import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setActiveModal } from '../../../../../redux/app-global';
import { EAppModal } from '../../../../../redux/app-global/app-global.types';
import { SharedService } from '../../../../../services/shared-service';

const useSubTable = () => {
  const dispatch = useDispatch();
  const downloadCode = async (name: string) => {
    try {
      const { REACT_APP_REPORT_API_URL } = process.env;
      const api = `${REACT_APP_REPORT_API_URL}/transform-artifacts/download`;
      const response = await axios.get(api, {
        params: {
          name,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log('err');
    }
  };

  const showCallChainModal = (name: string, type: string) => {
    SharedService.selectedProgram.name = name;
    SharedService.selectedProgram.type = type === 'Jobs' ? 'jcl' : 'program';
    dispatch(setActiveModal(EAppModal.CALL_CHAIN_MODAL));
  };

  return { downloadCode, showCallChainModal };
};

export default useSubTable;
