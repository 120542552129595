import { appTheme } from 'src/styles/theme';

export interface IUser {
  id: number;
  name: string;
  email: string;
  status: string;
  role: string;
}

export const fetchUsers = async () => {
  return [
    {
      id: 1,
      name: 'John Doe',
      email: 'jdoe@me.com',
      status: 'ACTIVE',
      role: 'admin',
    },
    {
      id: 2,
      name: 'Jane Smith',
      email: 'jsmith@me.com',
      status: 'ACTIVE',
      role: 'user',
    },
    {
      id: 3,
      name: 'Bob Johnson',
      email: 'bjohnson@me.com',
      status: 'ACTIVE',
      role: 'user',
    },
  ];
};

export const userStatusToColorMap = {
  INACTIVE: {
    background: appTheme.colors.grey20,
    color: '#000000',
  },
  ACTIVE: {
    background: '#008000',
    color: '#FFFFFF',
  },
};
