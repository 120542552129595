const ProjectOverviewTile = () => {
  return (
    <div className="">
      <div className="p-6 bg-white rounded-md shadow-md">
        <div className="space-y-8">
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Name :
            </span>
            <span style={{ width: '10rem' }}>Accounts Payable</span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Timeline :
            </span>
            <span style={{ width: '10rem' }}>3 Aug - 9 Dec</span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              File Type :
            </span>
            <span style={{ width: '10rem' }}>Smart Doc</span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Tasks :
            </span>
            <span style={{ width: '10rem' }}>15</span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Hold :
            </span>
            <div
              className="text-center rounded-full border px-3.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-gray-100 mr-auto"
              data-v0-t="badge"
            >
              Not Applicable
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverviewTile;
