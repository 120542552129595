import {
  FaFolderOpen,
  FaRunning,
  FaClock,
  FaCheckCircle,
} from 'react-icons/fa';
import ProjectNavigationTabs from './navigation-tab/navigation-tab';
import DashboardTile from 'src/components/dashboard-tile/dashboard-tile';
import { useEffect, useState } from 'react';
import {
  getProjectDependencies,
  getProjectDetails,
  getProjectInvites,
} from './dashboard.service';
import dayjs from 'dayjs';
import ProjectDependency from './project-dependency/project-dependency';
import AdminWrapper from './admin-wrapper/admin-wrapper';
import { useUser } from 'src/hooks/useUser';

const tilesData = (isAdmin: boolean) => [
  {
    title: 'Total Projects',
    value: isAdmin ? '13' : '05',
    icon: <FaFolderOpen />,
    bgColor: 'bg-blue-200',
    dropdownOptions: ['This Month', 'This Year', 'This Day'],
    percentageRate: '45%',
    iconColor: 'text-blue-500',
    percentageColor: 'text-green-500',
  },
  {
    title: 'Running Projects',
    value: isAdmin ? '06' : '03',
    icon: <FaRunning />,
    bgColor: 'bg-yellow-100',
    dropdownOptions: ['This Month', 'This Year', 'This Day'],
    percentageRate: '50%',
    iconColor: 'text-yellow-500',
    percentageColor: 'text-yellow-500',
  },
  {
    title: 'Dependency Hold',
    value: isAdmin ? '05' : '01',
    icon: <FaClock />,
    bgColor: 'bg-rose-200',
    dropdownOptions: ['This Month', 'This Year', 'This Day'],
    percentageRate: '10%',
    iconColor: 'text-rose-400',
    percentageColor: 'text-red-500',
  },
  {
    title: 'Completed Projects',
    value: isAdmin ? '02' : '0',
    icon: <FaCheckCircle />,
    bgColor: 'bg-green-200',
    dropdownOptions: ['This Month', 'This Year', 'This Day'],
    percentageRate: '100%',
    iconColor: 'text-green-500',
    percentageColor: 'text-green-600',
  },
];

const Dashboard = () => {
  const [projects, setProjects] = useState<any>([]);
  const [projectInvites, setProjectInvites] = useState<any>([]);
  const [projectDependencies, setProjectDependencies] = useState<any>([]);
  const user = useUser();

  useEffect(() => {
    async function fetchProjects() {
      let response = await getProjectDetails();
      if (response) {
        response = response.map((project: any) => {
          const timeLine = `${dayjs(project.startDate).format('MMM DD, YYYY')} - ${dayjs(project.dueDate).format('MMM DD, YYYY')}`;
          return {
            ...project,
            description: [timeLine, ...project.description],
          };
        });
        setProjects(response);
      }

      const inviteResponse = await getProjectInvites();
      if (inviteResponse) {
        setProjectInvites(inviteResponse);
      }

      const projectDependencyResponse = await getProjectDependencies();
      if (projectDependencyResponse) {
        setProjectDependencies(projectDependencyResponse);
      }
    }

    fetchProjects();
  }, []);

  return (
    <AdminWrapper>
      <div className="p-4 pb-[5rem] bg-[#F3F4F4] w-full">
        <div
          className="p-8 bg-white w-full"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
          <div className="mb-8">
            <h1 className="text-2xl font-bold mb-2">
              Welcome back {user?.userId}
            </h1>
            <p className="text-lg text-gray-600">
              Manage and transform your COBOL projects effortlessly. Get started
              by selecting a project to convert into Java.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 bg-white">
            {tilesData(user?.role === 'Admin').map((tile, index) => (
              <DashboardTile
                key={index}
                title={tile.title}
                value={tile.value}
                icon={tile.icon}
                bgColor={tile.bgColor}
                dropdownOptions={tile.dropdownOptions}
                percentageRate={tile.percentageRate}
                iconColor={tile.iconColor}
                percentageColor={tile.percentageColor}
              />
            ))}
          </div>
        </div>
        <div
          className="p-8 mt-[1rem] rounded-md bg-white"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
          <ProjectNavigationTabs
            projects={projects}
            projectInvites={projectInvites}
          />
        </div>
        <div
          className="p-8 mt-[1rem] rounded-md bg-white"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
          <ProjectDependency projectDependencies={projectDependencies} />
        </div>
      </div>
    </AdminWrapper>
  );
};

export default Dashboard;
