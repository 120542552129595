import axios from 'axios';
import { useEffect, useState } from 'react';

const useTransformation = () => {
  const [jcls, setJcls] = useState([]);
  const [driverPrograms, setDriverPrograms] = useState([]);
  const [otherPrograms, setOtherPrograms] = useState([]);
  const [availableProfiles, setAvailableProfiles] = useState({
    jcl: [],
    driverPrograms: [],
    otherPrograms: [],
  });

  useEffect(() => {
    async function fetchTransformationData() {
      try {
        const { REACT_APP_REPORT_API_URL } = process.env;
        const api = `${REACT_APP_REPORT_API_URL}/transform-artifacts`;
        const response = await axios.get(api);
        if (response?.data?.data) {
          const { jcl, driverPrograms, otherPrograms } = response.data.data;
          setJcls(jcl.artifacts || []);
          setDriverPrograms(driverPrograms.artifacts || []);
          setOtherPrograms(otherPrograms.artifacts || []);
          setAvailableProfiles({
            jcl: jcl.avalableProfiles || [],
            driverPrograms: driverPrograms.avalableProfiles || [],
            otherPrograms: otherPrograms.avalableProfiles || [],
          });
        } else {
          console.log('Error while fetching transformation data');
        }
      } catch (err) {
        console.log('Error while fetching transformation data', err);
      }
    }

    // fetchTransformationData();
  }, []);

  return { jcls, driverPrograms, otherPrograms, availableProfiles };
};

export default useTransformation;
