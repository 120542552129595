import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import AccessFullReport from '../access-full-report/access-full-report';
import { EAppModal } from '../../redux/app-global/app-global.types';
import FileSizeLimitModal from '../file-size-limit/file-size-limit';
import ScanningCompletedModal from '../scanning-done/scanning-done';
import ScanFailedModal from '../scan-failed/scan-failed';
import FileUploadFailedModal from '../file-upload-failed/file-upload-failed';
import GenericAlertModal from '../generic-alert-model/generic-alert-model';
import ScanJCLFailedModal from '../scan-jcl-failed/scan-jcl-failed';
import AssistedService from '../assisted-service/assisted-service';
import FeedbackModal from './feedback-modal/feedback-modal';
import LeadInformation from './lead-information/lead-information';
import ScheduleCalender from './schedule-calender/schedule-calender';
import AppVersion from './app-version/app-version';
import FileValidationExceeded from '../file-validation-exceeded/file-validation-exceeded';
import MissingArtifactsModal from './missing-artifacts/missing-artifacts';
import DocumentationModal from '../documentation-modal/documentation-modal';
import ExtractArtifactModal from './extract-artifact/extract-artifact.modal';
import SupportTicket from './support-ticket/support-ticket';
import CallChainModal from '../call-chain-modal/call-chain-modal';
import MissingArtifactAlert from './missing-artifact-alert/missing-artifact-alert';

const AppModals = () => {
  const activeModal = useSelector(
    (state: RootState) => state.appGlobal.activeModal,
  );
  const scannerErrorMessage = useSelector(
    (state: RootState) => state.appGlobal.scannerErrorMessage,
  );
  if (activeModal === EAppModal.ACCESS_FULL_REPORT) {
    return <AccessFullReport />;
  }
  if (activeModal === EAppModal.FILE_SIZE_LIMIT_EXCEEDED) {
    return <FileSizeLimitModal />;
  }
  if (activeModal === EAppModal.SCANNING_COMPLETED) {
    return <ScanningCompletedModal />;
  }
  if (activeModal === EAppModal.SCANNING_FAILED || scannerErrorMessage) {
    return <ScanFailedModal />;
  }
  if (activeModal === EAppModal.FILE_UPLOAD_FAILED) {
    return <FileUploadFailedModal />;
  }
  if (activeModal === EAppModal.FILE_VALIDATION_EXCEEDED) {
    return <FileValidationExceeded />;
  }
  if (activeModal === EAppModal.GENERIC_ALERT_MODAL) {
    return <GenericAlertModal />;
  }
  if (activeModal === EAppModal.SCAN_JCL_FAILED) {
    return <ScanJCLFailedModal />;
  }
  if (activeModal === EAppModal.ASSISTED_SERVICE) {
    return <AssistedService />;
  }
  if (activeModal === EAppModal.FEEDBACK) {
    return <FeedbackModal />;
  }
  if (activeModal === EAppModal.LEAD_INFORMATION) {
    return <LeadInformation />;
  }
  if (activeModal === EAppModal.SCHEDULE_CALENDER) {
    return <ScheduleCalender />;
  }
  if (activeModal === EAppModal.APP_VERSION) {
    return <AppVersion />;
  }
  if (activeModal === EAppModal.MISSING_ARTIFACTS) {
    return <MissingArtifactsModal />;
  }
  if (activeModal === EAppModal.DOCUMENTATION_MODAL) {
    return <DocumentationModal />;
  }
  if (activeModal === EAppModal.DATASET_DOWNLOAD) {
    return <ExtractArtifactModal />;
  }
  if (activeModal === EAppModal.SUPPORT_TICKET) {
    return <SupportTicket />;
  }
  if (activeModal === EAppModal.CALL_CHAIN_MODAL) {
    return <CallChainModal />;
  }
  if (activeModal === EAppModal.MISSING_ARTIFACT_ALERT) {
    return <MissingArtifactAlert />;
  }

  return null;
};

export default AppModals;
