import { INoteCardProps } from './notes-card.types';
import dayjs from 'dayjs';

const NotesCard = ({
  id,
  title,
  authorName,
  content,
  date,
  projectNames,
  projectType,
  onClick,
}: INoteCardProps & { onClick?: () => void }) => {
  const handleReadMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    // alert('Read More clicked');
  };

  return (
    <div onClick={onClick} className="cursor-pointer bg-white h-full w-full">
      <div
        className="rounded-lg border bg-card text-card-foreground w-full max-w-md p-4 shadow-lg h-full flex flex-col"
        data-v0-t="card"
      >
        <div className="flex-col space-y-1 p-4 flex justify-between items-center pb-0">
          <h3 className="whitespace-nowrap tracking-tight text-lg font-semibold">
            {title}
          </h3>
        </div>
        <div className="p-6 flex-grow space-y-2">
          <div className="text-sm text-muted-foreground">
            {dayjs(date).format('MMM DD')}
          </div>
          <p className="text-base">
            {content}{' '}
            <a
              className="text-blue-500 cursor-pointer"
              onClick={handleReadMore}
            >
              Read More
            </a>
          </p>
        </div>
        <p className="text-right mr-2 text-sm text-muted-foreground pb-2">
          {authorName}
        </p>
      </div>
    </div>
  );
};

export default NotesCard;
