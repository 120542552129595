import { useEffect, useState } from 'react';
import {
  FiArrowLeft,
  FiBell,
  FiLogOut,
  FiPlus,
  FiSettings,
} from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import NotificationWidget from './notification-widget/notification-widget';
import { setUserLoginDetails } from 'src/redux/app-global';
import { useUser } from 'src/hooks/useUser';
import { ROUTES } from 'src/App.navigation';
import { SharedService } from 'src/services/shared-service';
import Swal from 'sweetalert2';
import AdminOnly from 'src/components/admin-only/admin-only';
import UserOnly from 'src/components/user-only/user-only';

const Header = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const isOnPrem = license?.licenseType === 'ONPREM';
  const [showBackButton, setShowBackButton] = useState(false);
  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleHomeEvent = () => {
      setShowBackButton(true);
    };
    const handleAppEvent = () => {
      setShowBackButton(false);
    };
    window.addEventListener('Home', handleHomeEvent);
    window.addEventListener('App', handleAppEvent);

    return () => {
      window.removeEventListener('Home', handleHomeEvent);
      window.removeEventListener('App', handleAppEvent);
    };
  }, []);

  if (!isOnPrem) {
    return null;
  }
  const handleLogOut = () => {
    dispatch(setUserLoginDetails(null));
    SharedService.router?.navigate(ROUTES.LOGIN);
  };

  const handleProjectCreation = () => {
    Swal.fire({
      title: 'Create New Project [WIP]',
      html: `
        <input
          id="swal-input1"
          class="swal2-input"
          placeholder="Project Name"
        />
        <textarea
          id="swal-input2"
          class="swal2-textarea"
          placeholder="Project Description"
        ></textarea>
      `,
      showCancelButton: true,
      confirmButtonText: 'Create',
      preConfirm: () => {
        const projectName = (
          document.getElementById('swal-input1') as HTMLInputElement
        ).value;
        const projectDescription = (
          document.getElementById('swal-input2') as HTMLInputElement
        ).value;
        if (!projectName || !projectDescription) {
          Swal.showValidationMessage('Please enter all the required fields');
        }
        return { projectName, projectDescription };
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire(
          'Project Created!',
          'Your project has been created successfully',
          'success',
        );
      }
    });
  };

  return (
    <div className="fixed top-0 left-0 w-full px-4 py-8 rounded-md z-50">
      <div className="flex flex-row justify-between items-center">
        <div className="text-darkGrey font-semibold text-xl">
          <a href="/dashboard">
            <img
              src="https://cf1.cloudframe.com/wp-content/uploads/2022/03/CF-Logo-White.png"
              alt="CloudFrame"
              className="ml-2"
              style={{
                width: '200px',
                height: 'auto',
                cursor: 'pointer',
              }}
            />
          </a>
        </div>
        {user?.userId && (
          <div className="flex flex-row items-center">
            <AdminOnly>
              <div className="flex flex-row items-center mr-4">
                <button
                  onClick={handleProjectCreation}
                  className="flex items-center text-sm text-blue-700 bg-white border border-blue-300 rounded-full py-3 px-10 transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100 active:bg-gray-200 active:scale-95 shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-400 focus:ring-opacity-50"
                >
                  <FiPlus className="mr-2 text-lg" />
                  Create New Project
                </button>
                <div className="mx-4 h-8 w-px bg-gray-400" />
              </div>
            </AdminOnly>
            {showBackButton && (
              <UserOnly>
                <div className="flex flex-row items-center mr-4">
                  <button
                    onClick={() => {
                      if (SharedService.router) {
                        SharedService.router?.navigate(ROUTES.PROJECT_OVERVIEW);
                      } else {
                        window.location.href = './project-overview';
                      }
                    }}
                    className="flex items-center text-sm text-gray-700 bg-transparent border border-gray-300 rounded-full py-2 px-4 transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100 active:bg-gray-200 active:scale-95 shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 text-white hover:text-black"
                  >
                    <FiArrowLeft className="mr-2 text-lg" /> Back to Project
                    Overview
                  </button>
                  <div className="mx-4 h-8 w-px bg-gray-400" />
                </div>
              </UserOnly>
            )}
            <div className="flex flex-row items-center mr-4">
              <div className="hover:scale-110">
                <NotificationWidget />
              </div>
              <div
                className="relative flex items-center cursor-pointer"
                onClick={() => setTooltipVisible(!isTooltipVisible)}
                // onMouseEnter={() => setTooltipVisible(true)}
                // onMouseLeave={() => setTooltipVisible(false)}
              >
                <FaUserCircle
                  size={35}
                  className="hover:scale-110"
                  style={{ borderRadius: '50%', color: 'white' }}
                />

                {isTooltipVisible && (
                  <div className="absolute top-full right-0 sm:right-1 sm ml-1 mt-2 w-72 max-h-auto bg-white border border-gray-300 shadow-lg p-6 rounded-lg z-50">
                    <div className="flex flex-col items-center mb-4">
                      <FaUserCircle size={60} className="text-blue-600 mb-2" />

                      <p className="font-semibold text-md text-gray-800">
                        {user?.userId}
                      </p>
                      <p className="text-md text-gray-600">Developer</p>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center cursor-pointer hover:bg-gray-100 rounded p-3 transition-colors duration-300 ease-in-out">
                        <FiSettings size={24} className="mr-4 text-gray-600" />
                        <span className="text-md text-gray-800">Settings</span>
                      </div>
                      <div
                        className="flex items-center cursor-pointer hover:bg-gray-100 rounded p-3 transition-colors duration-300 ease-in-out"
                        onClick={handleLogOut}
                      >
                        <FiLogOut size={24} className="mr-4 text-gray-600" />
                        <span className="text-md text-gray-800">Logout</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
