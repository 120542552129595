import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';
import OverviewCards from './card/overview-cards';
import { Skeleton } from 'antd';

const ProjectReportPage = () => {
  const reportData = [
    {
      stage: 'Stage 1: Readiness',
      reports: [
        { title: 'Readiness Report 1', date: 'Aug 13' },
        { title: 'Readiness Report 2', date: 'Aug 15' },
      ],
    },
    {
      stage: 'Stage 2: Smart Doc',
      reports: [{ title: 'Smart Doc Report', date: 'Aug 18' }],
    },
    {
      stage: 'Stage 3: Transform',
      reports: [{ title: 'Transform Report', date: 'Aug 18' }],
    },
  ];
  return (
    <div className="flex flex-row bg-whiteSmoothLight w-full relative gap-4">
      <div className="mx-4 my-4 mb-0 pb-0 mr-0 w-8">
        <ProjectSideBar activeScreen={'Reports'} />
      </div>
      <div className="w-full h-[90vh] overflow-scroll mx-4 my-4 px-4 pb-8">
        <div>
          <h3 className="text-2xl font-bold my-4">Reports</h3>
          <p>
            Access detailed reports of your project transformation process.
            Review progress, identify any issues, and ensure a smooth transition
            from COBOL to Java.
          </p>
        </div>
        <div className="pb-8">
          {reportData.map((stageData, stageIndex) => (
            <div key={stageIndex} className="flex flex-col gap-4 my-4">
              <h4 className="text-xl font-semibold my-4">{stageData.stage}</h4>
              <div className="flex gap-4">
                {stageData.reports.map((report, reportIndex) => (
                  <OverviewCards key={reportIndex} height="250px" width="350px">
                    <h4 className="text-l font-semibold">{report.title}</h4>
                    <p className="text-sm font-light mt-2">{report.date}</p>
                    <img
                      src={require('../../assets/images/png/pdf.png')}
                      alt="PDF/HTML file icon"
                      style={{
                        height: 120,
                        width: 150,
                        borderRadius: '4px',
                        marginTop: '1rem',
                      }}
                    />
                  </OverviewCards>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProjectReportPage;
