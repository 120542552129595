import React from 'react';
import './table.css'; // Import custom CSS for styling
import SubTable from './sub-table/sub-table';
import { appTheme } from '../../../../styles/theme';
import HelpTextCallout from '../../../../components/help-text-callout/help-text-callout';

export interface ISubTable {
  id?: string;
  title: string;
  rows: { name: string }[];
  availableProfiles: { name: string; value: string }[];
  columnHelpTexts: { title: string; helpText: string }[];
  ButtonHelpTexts: { title: string; helpText: string }[];
}

interface ITable {
  title: string;
  description: string;
  tables: ISubTable[];
  helpText?: string;
}
const Table = ({ title, description, tables, helpText }: ITable) => {
  return (
    <div
      className="rounded-lg border bg-card text-card-foreground shadow-sm"
      data-v0-t="card"
    >
      <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-2xl font-bold text-white">{title}</h2>
          <HelpTextCallout 
            calloutText={helpText} 
            buttonStyles={{ 
              root: { color: 'white' }, 
              rootHovered: { backgroundColor: "#0A61DC", color: 'white' } 
            }} 
          />
        </div>
        <p className="text-sm text-white">{description}</p>
      </div>
      <hr />
      <div>
        {tables.map((table, index) => (
          <div
            key={index}
            style={{ borderBottom: '1px solid ' + appTheme.colors.grey20 }}
          >
            <SubTable 
              title={table.title} 
              rows={table.rows} 
              availableProfiles={table.availableProfiles} 
              columnHelpTexts={table.columnHelpTexts}
              ButtonHelpTexts={table.ButtonHelpTexts}
              id={table.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
