import axios from 'axios';
import { setLicense } from '../redux/app-global';
import { apiConfig, externalConfig } from '../utils/misc.utils';

export async function loadLicenseDetails(dispatch) {
  try {
    const basePath = externalConfig.REACT_APP_API_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    const response = await axios.get(`${basePath}/admin/api/config`, {
      headers: {
        'X-CF-CLIENT-ID': clientId,
        'X-CF-CLIENT-SECRET': clientSecret,
      },
    });
    Object.assign(apiConfig, response?.data?.data);
    const limit = parseInt(response?.data?.data?.maxFileSize) || 100;
    dispatch(
      setLicense({
        fileUploadLimit: limit,
        licenseType: response?.data?.data?.LicenseType,
        enableCdn: response?.data?.data?.EnableCDN,
        licenseFor: response?.data?.data?.LicenseFor,
      }),
    );
  } catch (error) {
    dispatch(
      setLicense({
        fileUploadLimit: 1000,
        licenseType: 'ONPREM',
        enableCdn: '',
        licenseFor: 'sahil',
      }),
    );
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching public email domains:', error);
    }
  }
}

export const fetchUiConfig = async (): Promise<any> => {
  try {
    if (process.env.REACT_APP_CF_ENV === 'onprem') {
      const response = await axios.get('ui/config');
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return '';
};
