import { appTheme } from 'src/styles/theme';

export const fetchSupportTickets = async () => {
  return [
    {
      id: 'CF-3456',
      title: 'Initial Code Analysis',
      authorName: 'John Doe',
      status: 'TODO',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3457',
      title: 'Bug Fixing',
      authorName: 'Jane Smith',
      status: 'IN_PROGRESS',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3458',
      title: 'Feature Enhancement',
      authorName: 'Alex Johnson',
      status: 'IN_PROGRESS',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3459',
      title: 'UI Design Issue',
      authorName: 'Emily Brown',
      status: 'TODO',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3460',
      title: 'Database Connection Error',
      authorName: 'Michael Wilson',
      status: 'DONE',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3461',
      title: 'Performance Optimization',
      authorName: 'David Thompson',
      status: 'TODO',
      type: 'Feature',
      link: '#',
    },
  ];
};

export const ticketStatusToColorMap = {
  TODO: {
    background: appTheme.colors.grey20,
    color: '#000000',
  },
  IN_PROGRESS: {
    background: '#4169E1',
    color: '#FFFFFF',
  },
  DONE: {
    background: '#008000',
    color: '#FFFFFF',
  },
};
