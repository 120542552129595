import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setActiveModal, setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';
import { Select } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import {
  ExtractDataSet,
  ExtractJclRequest,
  ExtractType,
} from '../../../pages/intent-pages/extract-artifact/extract-artifact.model';
import { EXTRACT_DATA_KEY } from '../../../pages/intent-pages/extract-artifact/extract-artifact';
import { downloadExtractJcl } from '../../../utils/misc.utils';
import HelpTextCallout from '../../help-text-callout/help-text-callout';
import { helpTexts } from '../../../helpTexts';

const ExtractArtifactModal = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const pageHelpTexts = helpTexts[0].EXTRACT_ARTIFACT;
  const activeModal = useSelector(
    (state: RootState) => state.appGlobal.activeModal,
  );
  const [options, setOptions] = useState<any[]>([]);
  const [dataset, setDatasets] = useState<ExtractDataSet>({});

  const handleChange = (value: any) => {
    if(value?.trim()) {
      const request: ExtractJclRequest = {downloadFor: value, dataset};
      downloadExtractJcl(request);
    }
  };

  useEffect(() => {
    const datasetStr = sessionStorage.getItem(EXTRACT_DATA_KEY) || '{}';
    const optionList: ReactNode[] = [];
    try {
      const datasetData: ExtractDataSet = JSON.parse(datasetStr);
      sessionStorage.removeItem(EXTRACT_DATA_KEY);
      if (Object.keys(datasetData).length) {
        setDatasets(datasetData);
        if (datasetData.cc) {
          optionList.push(
            <Option key="cc" value={ExtractType.CC}>
              Download Extraction JCL for Control Cards
            </Option>,
          );
        }
        if (datasetData.listing) {
          optionList.push(
            <Option key="listing" value={ExtractType.LISTING}>
              Download Extraction JCL for Compiler Listings
            </Option>,
          );
        }
        if (datasetData.proc) {
          optionList.push(
            <Option key="proc" value={ExtractType.PROC}>
              Download Extraction JCL for Procs
            </Option>,
          );
        }
        if (datasetData.jcl) {
          optionList.push(
            <Option key="jcl" value={ExtractType.JCL}>
              Download Extraction JCL for JCLs
            </Option>,
          );
        }
        if (datasetData.metadata) {
          optionList.push(
            <Option key="metadata" value={ExtractType.METADATA}>
              Download Extraction JCL for Metadata
            </Option>,
          );
        }
        if (Object.keys(datasetData).length > 1) {
          optionList.push(
            <Option key="all" value={ExtractType.ALL}>
              Download single JCL to Extract all Artifacts
            </Option>,
          );
        }
        setOptions(optionList);
      }
    } catch (error: any) {
      console.error(error);
    }
  }, [activeModal]);

  const handleClose = () => {
    dispatch(setActiveModal(null));
  };

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50">
      <div className="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-auto max-w-2xl max-h-[44rem] min-h-[30rem] border rounded-lg bg-white p-8">
        <div className="flex flex-col justify-start items-center p-4 pt-0">
          <button
            className="absolute top-2 right-2 text-xl text-bold text-ddlGrey40 cursor-pointer p-2 transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-200"
            onClick={handleClose}
          >
            X
          </button>
          <div>
            <p className="text-xl font-semibold">Select the JCL Format</p>
          </div>
          <div className="h-[1px] mt-4 mb-2 w-full bg-ddlGrey40" />
          <div className="w-full mt-4 text-left">
            <div className='flex flex-row items-center'>
              <HelpTextCallout calloutText={pageHelpTexts[6].description}/>
              <div className="w-full">
                Please select the type of extract JCL you want to download.
              </div>
          </div>          
            <Select
              className="w-full"
              placeholder="Please select"
              onChange={handleChange}
              open
              showSearch={false}
            >
              {options}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtractArtifactModal;
