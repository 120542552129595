import { AvatarStack } from 'src/components/ui/avatar-stack';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import ProjectTile from 'src/components/project-tile/project-tile';
import ProgressSpinner from 'src/components/progress-spinner/progress-spinner';
import { getProgressStateColor } from 'src/pages/dashboard/navigation-tab/navigation-tab';
import { useNavigate } from 'react-router-dom';
import { useGetProjectsQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { ROUTES } from 'src/App.navigation';

const AdminNotesPage = () => {
  const { isLoading, data: projects, error } = useGetProjectsQuery();
  const navigate = useNavigate();

  if (error) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load projects. Please try again later.',
    });
  }

  const handleProjectClick = (id: string | number) => {
    navigate(`${ROUTES.NOTES}?projectId=${id}`);
  };

  const renderProjectCards = () => {
    return projects?.map(item => (
      <ProjectTile
        // className="min-w-[415px]"
        key={item.id}
        onClick={() => handleProjectClick(item.id)}
        topLeftIcon={
          <ProgressSpinner
            progress={item.progress}
            progressColor={getProgressStateColor(item.progress)}
          />
        }
        topRightContent={<div />}
        actionCta={
          <AvatarStack
            maxAvatarsAmount={3}
            avatars={[
              'https://github.com/shadcn.png',
              'https://github.com/shadcn.png',
              'https://github.com/shadcn.png',
              'https://github.com/shadcn.png',
            ]}
          />
        }
        {...item}
      />
    ));
  };

  return (
    <div className="p-0 flex flex-row bg-[#F3F4F4] h-[100vh]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'Notes'} />
      </div>
      <div className="mt-8 ml-8 w-full">
        <div className="w-full">
          <h1 className="text-2xl font-bold">Notes</h1>
          <p className="mt-2">
            Keep important details, ideas, and observations in one place. Use
            this section to jot down any notes related to your project's
            transformation journey.
          </p>
          <div className="mr-6">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mt-8">
              {isLoading ? 'Loading...' : renderProjectCards()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNotesPage;
