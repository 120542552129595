import { parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js';
import { ApiConfig } from '../model/license.model';
import axios, { AxiosResponse } from 'axios';
import { ExtractJclRequest } from '../pages/intent-pages/extract-artifact/extract-artifact.model';
import { saveAs } from 'file-saver';

export const externalConfig: any = {};
export const apiConfig: ApiConfig = {};

export function scrollInformationCenterToEnd() {
  const infoCenter = document.getElementById('information-center');
  if (infoCenter) {
    infoCenter.scrollTop = infoCenter.scrollHeight;
  }
}

export function scrollInformationCenterToTop() {
  const infoCenter = document.getElementById('information-center');
  if (infoCenter) {
    infoCenter.scrollTop = 0;
  }
}

export const isValidInternationalPhoneNumber = (number: string) => {
  // Phones for test
  // +84967711901 (VN)
  // +441481244783 (GB)
  // +12133734253 (US)
  // +4915754449596 (DE)

  // Uncomment to choose best choice
  // 1.
  const phoneNumber = parsePhoneNumber(number);
  return phoneNumber && phoneNumber.isValid();
  // 2.
  // isPossiblePhoneNumber() only validates phone number length,
  // return isPossiblePhoneNumber(number, country);
  // 3.
  // while isValidPhoneNumber() validates both phone number length and the actual phone number digits.
  // return isValidPhoneNumber(number, country);
};

export function formatOptionId(id: string): string {
  const [numericPart, alphabeticPart] = id
    .match(/(\d+)([a-zA-Z]*)/)
    ?.slice(1) || ['', ''];
  return parseInt(numericPart, 10) + alphabeticPart.toUpperCase();
}

export function processImageSource(source: string): string {
  if (source) {
    if (process.env.REACT_APP_CF_ENV === 'onprem') {
      const idxAsset = source.indexOf('assets');
      if (idxAsset > -1) {
        return source.substring(idxAsset);
      }
    }
    return source;
  }
  return '';
}

export function isValidDataset(dataset: string): string {
  if (!dataset) {
    return '';
  }
  const parts = dataset.split('.');
  if (parts.length > 0 && parts.length <= 3) {
    for (const part of parts) {
      if (!part.trim()) {
        return `A dataset/qualifier cannot be empty`;
      }
      if (!/^[A-Z$#@][A-Z0-9$#@-]{0,7}$/i.test(part)) {
        return `'${part}' is invalid qualifier`;
      }
    }
    if (dataset.length > 44) {
      return 'Dataset name exceeds 44 characters';
    } else {
      return '';
    }
  }
  return 'Invalid dataset name';
}

export function commaSeparated(dataset: string): string {
  if (!dataset) return '';

  if (dataset.includes(',,')) return 'Consecutive commas are not allowed';

  return dataset
    .split(',')
    .map(part => part.trim())
    .filter(Boolean) 
    .map(validateDatasetPart)
    .find(error => error) || ''; 
}

function validateDatasetPart(part: string): string | null {
  if (part.length > 44) return 'Dataset name exceeds 44 characters';

  const subParts = part.split('.');
  if (subParts.length < 1 || subParts.length > 3) return 'Invalid dataset name';

  for (const subPart of subParts) {
    if (!/^[A-Z$#@][A-Z0-9$#@-]{0,7}$/i.test(subPart)) {
      return `'${subPart}' is an invalid qualifier`;
    }
  }
  return null;
}


export const downloadTextFile = (data: any, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, fileName);
};

export const downloadBase64FromResponse = (
  response: AxiosResponse<any, any>,
) => {
  const fileData = response.data.data;
  const fileName = response.data.message;
  const fileBlob = new Blob(
    [
      new Uint8Array(
        atob(fileData)
          .split('')
          .map(c => c.charCodeAt(0)),
      ),
    ],
    { type: 'application/octet-stream' },
  );
  saveAs(fileBlob, fileName);
};

export const downloadExtractJcl = async (requestData: ExtractJclRequest) => {
  try {
    const response = await axios.post(
      `${externalConfig.REACT_APP_API_URL}/jcl/extract`,
      requestData,
    );
    if (response.data.status === 'SUCCESS') {
      downloadBase64FromResponse(response);
      return true;
    } else {
      console.error('Error downloading the file', response.data.message);
      return false;
    }
  } catch (error) {
    console.error('Error downloading the file:', error);
    return false;
  }
};
