const TransformStatusTable = ({ data, type }) => {
  const tableStyles = {
    green: {
      borderColor: 'border-green-200',
      bgColor: 'bg-green-100',
    },
    red: {
      borderColor: 'border-red-200',
      bgColor: 'bg-red-100',
    },
  };

  const styles = tableStyles[type] || tableStyles.green;

  return (
    <div className={`mt-0 p-2 ${type === 'red' ? 'bg-gray-100' : ''}`}>
      <table
        className={`full bg-white border-collapse border ${styles.borderColor} w-1/2 mt-4 ml-4 shadow-md rounded-lg`}
      >
        <thead>
          <tr>
            <th
              className={`py-2 px-4 sm:px-6 border-b ${styles.borderColor} text-left text-sm font-semibold text-gray-700 uppercase tracking-wider ${styles.bgColor}`}
            >
              Artifact
            </th>
            <th
              className={`py-2 px-4 sm:px-6 border-b ${styles.borderColor} text-left text-sm font-semibold text-gray-700 uppercase tracking-wider ${styles.bgColor}`}
            >
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td
                className={`py-2 px-4 sm:px-6 border-b ${styles.borderColor} text-sm text-gray-600`}
              >
                {row.name}
              </td>
              <td
                className={`py-2 px-4 sm:px-6 border-b ${styles.borderColor} text-sm text-gray-600`}
              >
                {row.type.toUpperCase()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransformStatusTable;
