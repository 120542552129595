export const FileTypeIcons = {
  pdf: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 4a1 1 0 011 1v6.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L8 11.586V5a1 1 0 011-1zm2 9.414V14a1 1 0 01-2 0v-2a1 1 0 011-1h1a1 1 0 011 1zM10 8a1 1 0 011 1v1a1 1 0 11-2 0V9a1 1 0 011-1zm0 7a1 1 0 100-2 1 1 0 000 2z"
        clipRule="evenodd"
      />
    </svg>
  ),
  txt: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4 4a1 1 0 011-1h10a1 1 0 011 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm10 10a1 1 0 00-1-1H5a1 1 0 00-1 1v3a1 1 0 001 1h8a1 1 0 001-1v-3zm-5-7a1 1 0 00-1 1v5a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  ),
  doc: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4 4a1 1 0 011-1h10a1 1 0 011 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm10 10a1 1 0 00-1-1H5a1 1 0 00-1 1v3a1 1 0 001 1h8a1 1 0 001-1v-3zm-5-7a1 1 0 00-1 1v5a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  ),
  // Add more file types and their corresponding SVG icons here
};

export const FileDownloadIcon = (props: {
  color?: string;
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      fill={`${props.color || '#000000'}`}
      height={`${props.height || '100%'}`}
      width={`${props.width || '100%'}`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M226.133,469.333H55.467V409.6c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v68.267
         c0,4.71,3.823,8.533,8.533,8.533h179.2c4.71,0,8.533-3.823,8.533-8.533S230.844,469.333,226.133,469.333z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M46.933,366.933c-4.71,0-8.533,3.823-8.533,8.533S42.223,384,46.933,384h0.085c4.71,0,8.491-3.823,8.491-8.533
         S51.644,366.933,46.933,366.933z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M394.3,139.034L257.766,2.5c-1.596-1.604-3.772-2.5-6.033-2.5h-204.8C42.223,0,38.4,3.823,38.4,8.533v332.8
         c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533V17.067H243.2v128c0,4.71,3.823,8.533,8.533,8.533h128v76.8
         c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-85.333C396.8,142.805,395.904,140.629,394.3,139.034z M260.267,136.533
         V29.133l107.401,107.401H260.267z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M401.178,339.985c-3.354-23.296-23.117-41.318-46.686-41.318c-14.515,0-28.254,6.98-37.146,18.56
         c-14.677-1.63-29.124,7.765-34.005,21.879c-10.863,6.912-17.698,19.217-17.698,32.367c0,21.026,16.725,38.127,37.291,38.127
         c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533c-11.153,0-20.224-9.446-20.224-21.06
         c0-8.055,4.582-15.522,11.665-19.021c2.338-1.161,4.028-3.319,4.574-5.871c1.877-8.841,11.196-14.524,19.55-11.716
         c3.823,1.297,8.081-0.299,10.112-3.831c5.521-9.583,15.198-15.3,25.882-15.3c16.384,0,29.892,13.833,30.114,30.839
         c0.051,4.045,2.935,7.492,6.912,8.26c8.653,1.681,14.925,9.54,14.925,18.679c0,10.487-8.183,19.021-18.244,19.021
         c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h0.068c0.026,0,0.043,0,0.068,0
         c19.405-0.077,35.174-16.23,35.174-36.079C423.509,358.537,414.43,345.344,401.178,339.985z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M345.6,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S416.179,256,345.6,256z M345.6,494.933
         c-61.167,0-110.933-49.766-110.933-110.933S284.433,273.067,345.6,273.067S456.533,322.833,456.533,384
         S406.767,494.933,345.6,494.933z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M377.233,429.167c-3.337-3.337-8.73-3.336-12.066,0l-11.034,11.034V384c0-4.71-3.814-8.533-8.533-8.533
         c-4.719,0-8.533,3.823-8.533,8.533v56.201l-11.034-11.034c-3.337-3.336-8.73-3.336-12.066,0c-3.337,3.337-3.337,8.73,0,12.066
         l25.6,25.6c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5l25.6-25.6
         C380.57,437.897,380.57,432.503,377.233,429.167z"
          />
        </g>
      </g>
    </svg>
  );
};
