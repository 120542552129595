import {
  Checkbox,
  initializeIcons,
  PrimaryButton,
  Toggle,
  TooltipHost
} from '@fluentui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MissingUploadList, { UploadMissingListRef } from '../../../components/missing-artifact-upload/missing-artifact-upload';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import {
  setActiveModal,
  setCurrentIntent,
  setNextMissingPage,
  setNextMissingPageTest,
  setProcData,
  setUploadedArtifacts,
  setUploadedMissingArtifacts
} from '../../../redux/app-global';
import { EAppIntent, EAppModal, IUploadedArtifacts, TableData } from '../../../redux/app-global/app-global.types';
import { SharedService } from '../../../services/shared-service';
import { commaSeparated, downloadExtractJcl, externalConfig, isValidDataset } from '../../../utils/misc.utils';
import { VALID_PROC_FILE_TYPES } from './missing-procs-types';
import { RootState } from '../../../redux/store';
import { ExtractJclRequest, ExtractType } from '../extract-artifact/extract-artifact.model';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import LoaderComponent from '../../../components/loader/loader.component';
import { FaSpinner } from 'react-icons/fa';

initializeIcons();

const MissingProcs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [toggleValue, setToggleValue] = useState<boolean>(false);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const [data, setData] = useState<TableData[]>([]);
  const [missingArtifacts, setMissingArtifacts] = useState<IUploadedArtifacts>();
  const isMounted = useRef(false);
  const [datasetNames, setDatasetNames] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);
  const procData = useSelector((state: RootState) => state.appGlobal.procData);
  const uploadedMissingArtifacts = useSelector((state: RootState) => state.appGlobal.uploadedMissingArtifacts);
  const pagHelpTexts = helpTexts[0].MISSING_PROC;
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);

  const triggerFileUpload = (event, itemKey) => {
    if (uploadMissingListRef.current) {
      uploadMissingListRef.current.handleFileUpload(event, itemKey);
    }
  };  
  const handleFilesUpdate = (updatedFiles) => {
    setFiles(updatedFiles);
  };
  const dispatch = useDispatch();

  const handleCheckboxChange = key => {
    setSelectedRows(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
    setData(prevData =>
      prevData.map(item =>
        item.key === key
          ? { ...item, status: !selectedRows[key] ? 'Ignore' : 'Active' }
          : item,
      ),
    );
  };  
  const sendProcInfo = async () => {
    const formData = new FormData();
    const requestBody:any = [];
    data.forEach(row => {
      const item = {
        name: row.name,
        status: row.status  || '',
        file: row.uploaded || '', 
        referredIn: row.requiredBy || [], 
      };
      requestBody.push(item);
    });

    formData.append('missingData', JSON.stringify(requestBody));

    Object.values(files).forEach(file => {
      console.log((file as any).originalFile)
      if (file) {
        formData.append('fileList', (file as any).originalFile);
      }
    });

    // if ((data.some(
    //   item => item.status === 'Active' && item.uploaded === null,
    // ) &&
    //   !(Object.values(files).length > 0))) {
    //     dispatch(setActiveModal(EAppModal.MISSING_ARTIFACT_ALERT));
    //     return;
    // }
    try {
      setLoading(true)
      const response = await axios.post(
        `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/PROC`,
        formData,
      );
      if(response.data?.data) {        
        setLoading(false)
        SharedService.genericAlertModal.title = 'Few Missing Artifacts not Uploaded!';
        SharedService.genericAlertModal.description =
          'The Information which is necessary for complete transformation is not provided. Please provide all the missing artifacts information to get started with transformation!';
        setTimeout(() => {
          dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
        }, 0);
        const transformedData: TableData[] = response.data?.data.map(
          (item: any, index: number) => ({
              key: index,
              status: 'Active',
              name: item.name,
              requiredBy: item.referredIn,
              uploaded: null,
          }),
        );
        setData(transformedData); 
        return;
      }
      dispatch(setUploadedMissingArtifacts(missingArtifacts));
      dispatch(setProcData(data));
      const currentIndex = missingPages.findIndex((page) => page.status === true);
      if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
        const nextIndex = currentIndex + 1;
        const nextPage = missingPages[nextIndex];
        dispatch(setCurrentIntent(nextPage.key));
        dispatch(setNextMissingPage({
          page: nextPage.key,
        }));
        const updatedMissingPages = missingPages.map((page, index) =>
          index === nextIndex ? { ...page, status: true }
            : index === currentIndex ? { ...page, status: false }
              : page
        );
        dispatch(setNextMissingPageTest(updatedMissingPages));
      } else {
        dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
        dispatch(setNextMissingPage({ page: undefined }));
      }
    } catch (err) {
      setLoading(false)
      console.log(err);
      SharedService.genericAlertModal.title = 'Upload of Missing Artfacts Failed!';
      SharedService.genericAlertModal.description =
        (err as any)?.message || 'Something went wrong !';
      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
      }, 0);
    }
  };

  useEffect(() => {
    if (procData && procData?.length > 0) {
      setData(procData);
      return;
    }
    async function getMissingProcs() {
      try {        
        const response = await axios.get(
          `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/PROC`,
        );
        const transformedData: TableData[] = response.data?.data?.map(
          (item: any, index: number) => ({
            key: index,
            status: 'Active',
            name: item.name,
            requiredBy: item.referredIn,
            uploaded: null,
          }),
        ) || [];
        setData(transformedData);
      } catch (err) {
        console.error(err);
      }
    }
    if (!isMounted.current) {
      getMissingProcs();
      isMounted.current = true;
    }
  }, []);

  const handleSelectAllCheckboxChange = (event, checked) => {
    setSelectAll(checked);
    const newSelectedRows = {} as Record<number, boolean>;
    (data || []).forEach(item => {
      newSelectedRows[item.key] = checked;
    });
    const newData = (data || []).map(item => {
      newSelectedRows[item.key] = checked;
      return { ...item, status: checked ? 'Ignore' : 'Active' };
    });
    setSelectedRows(newSelectedRows);
    setData(newData);
  };

  const changeDisplay = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    setToggleValue(checked ?? false);
  };

  const headers: React.ReactNode[] = [
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllCheckboxChange}
      title="Ignore All"
      label="Ignore All"
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
      key="1"
    />,
    'Proc',
    'Artifact',
    !toggleValue && !(data?.every(item => item.uploaded) || false) && 'Upload',
  ];

  const rowFields = item => [
    <Checkbox
      checked={selectedRows[item.key] || false}
      onChange={() => handleCheckboxChange(item.key)}
      className="mt-2"
      title="Ignore"
      label="Ignore"
      key={item.key}
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
    />,
    <div
      className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
      key={item.key}
    >
      {item.name}
    </div>,
    item.requiredBy?.jcl?.length <= 4 ? (
      <div
        className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
        key={item.key}
      >
        {item.requiredBy?.jcl?.join(', ')}
      </div>
    ) : (
      <div key={item.key}>
        <TooltipHost
          id={`tooltip-${item.key}`}
          content={item.requiredBy?.jcl
            ?.slice(4, item.requiredBy?.jcl.length - 1)
            .join(', ')}
        >
          <div
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            {item.requiredBy?.jcl?.slice(0, 3).join(', ')}
          </div>
          <span
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            ... and {item.requiredBy?.jcl?.length - 3} more
          </span>
        </TooltipHost>
      </div>
    ),
    selectedRows[item.key] ? (
      item.uploaded ? (
        <MissingUploadBtn
          handleFileUpload={triggerFileUpload}
          item={item}
          buttonStyle={{ cursor: 'not-allowed', backgroundColor: '#f0f0f0' }}
        />
      ) : ""
    ) : !item.uploaded && (
      !toggleValue && <MissingUploadBtn handleFileUpload={triggerFileUpload} item={item} accept={`${VALID_PROC_FILE_TYPES.map(type => '.' + type).join(',')}`}/>
    ),
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setError(validationError);
      setSuccess(null);
    } else {
      setError(null);
      setSuccess(null);
    }
    setDatasetNames(value);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const validationError = commaSeparated(datasetNames.trim());
    if (validationError) {
      setError(validationError);
      setSuccess(null);
      return;
    }
    if (!datasetNames.trim()) {
      setError('Dataset name is required.');
      setSuccess(null);
      return;
    }

    const data: ExtractJclRequest = {
      dataset: {
        proc: [datasetNames.trim()]
      },
      downloadFor: 'PROC' as ExtractType
    };

    const isDownloadSuccessful = await downloadExtractJcl(data);

    if (isDownloadSuccessful) {
      setSuccess('Data successfully posted and file downloaded!');
      setError(null);
      setDatasetNames('');
    } else {
      setError('Failed to post data or download the file. Please try again.');
      setSuccess(null);
    }
  };

  return (
    <div
      className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}
    >
      <div className='p-2 px-8 mb-4'>
        <StepperComponent /> 
      </div>
      <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
        <h1 className="text-3xl font-semibold mb-4">
          Missing Artifacts Workspace
        </h1>
        <hr />
        <div className="flex flex-col gap-4">
          <div className="text-left mt-4 gap-4 text-l font-semibold">
            Missing PROC Information
          </div>
          <div>
          Upload the missing PROC or provide the dataset information to extract the missing PROC from the mainframe.
          </div>
          <div className="flex items-center gap-4">
            <HelpTextCallout calloutText={pagHelpTexts[4].description}/>
            <span className="text-sm">Upload PROC</span>
            <Toggle className="mt-2" onChange={changeDisplay} />
            <span className="text-sm">Extract PROCs from Mainframe</span>
          </div>
          {toggleValue && (
            <div>
              <h3>Enter the dataset names separated by commas:</h3>
              <input
                type="text"
                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                value={datasetNames}
                onChange={handleChange}
                placeholder="Enter dataset names..."
              />
              {error && (
                <TooltipHost content={error} id="error-tooltip">
                  <div className="mt-2 text-red-500">{error}</div>
                </TooltipHost>
              )}
              {success && (
                <TooltipHost content={success} id="success-tooltip">
                  <div className="mt-2 text-green-500">{success}</div>
                </TooltipHost>
              )}
            </div>
          )}
          {toggleValue ? (
            <div>
              {(data.every(
                item => item.status !== 'Active' || item.uploaded !== undefined,
              ) ||
                Object.values(files).length > 0 ||
                toggleValue ||
                true) && (
                  <div className='flex flex-row items-center mt-8'>
                    <HelpTextCallout calloutText={pagHelpTexts[6].description}/>
                    <PrimaryButton
                      text={'Generate Extraction JCL'}
                      className="rounded-md"
                      styles={{ root: { backgroundColor: '#1363DF' } }}
                      onClick={handleSubmit}
                    />
                  </div>
                )}
            </div>
          ) : (
            ""
          )}
          {!toggleValue && (
            <>
              <div className="font-semibold mt-4">Upload PROC as File</div>
              <hr />
            </>
          )}         
          <ReusableTable items={data} headers={headers} rowFields={rowFields} helpTexts={pagHelpTexts}/>
          {!toggleValue ? (
            <MissingUploadList ref={uploadMissingListRef} setData={setData} setMissingArtifacts={setMissingArtifacts as any} type="PROCs" data={data} onFilesUpdate={handleFilesUpdate} pageHelpText={pagHelpTexts}/>         
          ) : ''}
        </div>                  
        {!toggleValue && (
          <div>
            {(data.every(
              item => item.status !== 'Active' || item.uploaded !== undefined,
            ) ||
              Object.values(files).length > 0 ||
              toggleValue ||
              true) && (
                <div className='flex flex-row gap-4 items-center'>
                  <PrimaryButton
                    text="Save and Continue"
                    className="mt-8 rounded-md"
                    styles={{ root: { backgroundColor: '#1363DF' } }}
                    onClick={sendProcInfo}
                    disabled={loading}
                  />
                  {loading && <div className='flex gap-2 mt-4'><FaSpinner className="animate-spin"/><p>Updating Missing data ....</p></div>}
                </div>
              )
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default MissingProcs;
