const OverviewCards = ({ height = 'auto', width = 'auto', children }) => {
  return (
    <div
      className="bg-white rounded-md shadow-md p-6"
      style={{ height, width }}
    >
      {children}
    </div>
  );
};
export default OverviewCards;
