import axios from 'axios';
import { externalConfig } from '../../utils/misc.utils';

export async function triggerOtpForEmail(email: string) {
  const result = await axios.post(
    `${externalConfig.REACT_APP_API_URL}/user/send-otp`,
    {
      email,
    },
  );

  return result.data;
}
