import { useDispatch, useSelector } from 'react-redux';
import SVGAlert from '../../../assets/images/svg/alert.icon';
import { RootState } from '../../../redux/store';
import { setActiveModal, setCurrentIntent, setNextMissingPage, setNextMissingPageTest } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';

const MissingArtifactAlert = () => {
  const dispatch = useDispatch();
  const missingPages = useSelector((state:RootState)=>state.appGlobal.missingPages);
  const moveToNextPage = () => {
    dispatch(setActiveModal(null));
    const currentIndex = missingPages.findIndex((page) => page.status === true);
      if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
        const nextIndex = currentIndex + 1;
        const nextPage = missingPages[nextIndex];
        dispatch(setCurrentIntent(nextPage.key));
        dispatch(setNextMissingPage({
          page: nextPage.key,
        }));
        const updatedMissingPages = missingPages.map((page, index) =>
          index === nextIndex ? { ...page, status: true }
            : index === currentIndex ? { ...page, status: false }
              : page
            );
            dispatch(setNextMissingPageTest(updatedMissingPages));
          } else {
            dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
            dispatch(
                setNextMissingPage({
                page: undefined,
                }),
            );
          }
  }
  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div className="relative overflow-auto max-w-xl mx-auto max-h-[44rem] mt-[2%] flex w-full flex-col border rounded-lg bg-white p-8">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            paddingTop: '1.5rem',
          }}
        >
          <div>
            <SVGAlert />
          </div>
          <div style={{ marginTop: '2rem' }}>
            <p className="text-xl font-semibold">Missing Artifacts Information not provided!</p>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <p>
              The Information which is necessary for complete transformation is not provided.
              Please provide all the missing artifacts information to get started with transformation!
            </p>
            <p style={{ marginTop: '.5rem' }}>
              Do you wish to proceed anyhow?
            </p>
          </div>
          <div>
            <div className="flex fex-row gap-4 mt-8">
              <button
                className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                onClick={() => {
                  moveToNextPage();
                }}
              >
                Proceed
              </button>
              <button
                style={{ borderWidth: 1, minWidth: '8rem', borderRadius: 4 }}
                onClick={() => dispatch(setActiveModal(null))}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissingArtifactAlert;
