import { useDispatch } from 'react-redux';
import { setActiveModal } from '../../redux/app-global';
import CanvasComponent from '../canvas/canvas';
import { useCallChainUtils } from '../../hooks/useCallChainUtils';
import { SharedService } from '../../services/shared-service';
import { useEffect } from 'react';

const CallChainModal = () => {
  const dispatch = useDispatch();
  const {
    svgString,
    selectedProgramId,
    usedColors,
    setSelectedProgramId,
    error,
  } = useCallChainUtils();

  const progName = SharedService.selectedProgram.name;
  const progType = SharedService.selectedProgram.type;

  useEffect(() => {
    setSelectedProgramId(SharedService.selectedProgram.name);
  }, []);

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div className="relative overflow-auto max-w-4xl mx-auto max-h-[44rem] mt-[4%] flex w-full flex-col border rounded-lg bg-white p-8">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '.5rem',
            position: 'relative',
          }}
        >
          <CanvasComponent
            selectedProgramId={selectedProgramId}
            setSelectedProgramId={selectedProgramId}
            svgString={svgString}
            programIds={progType === 'jcl' ? [] : [progName]}
            jclArtifactIds={progType === 'jcl' ? [progName] : []}
            usedColors={usedColors}
            inModal={true}
          />
          <div className="mr-auto ml-1">
            <div className="flex fex-row gap-4 mt-8">
              <button
                className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                onClick={() => dispatch(setActiveModal(null))}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallChainModal;
