import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  getDraftDetails,
  getProjectDetails,
  IProjectApiObject,
} from 'src/pages/dashboard/dashboard.service';
import dayjs from 'dayjs';
import { fetchUsers, IUser } from 'src/pages/admin/users/users.service';
import { getGlobalProjectSettings } from 'src/pages/admin/global-project-settings/global-project-settings.service';
import { getProjectDetail } from 'src/pages/project-overview/project-overview.service';
import { getProjectNotes } from 'src/pages/notes/notes.service';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_GATEWAY_URL }),
  endpoints: builder => ({
    getProjects: builder.query<IProjectApiObject[], void>({
      queryFn: async () => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const response = await getProjectDetails();
            const formattedResponse = response.map((project: any) => {
              const timeLine = `${dayjs(project.startDate).format('MMM DD, YYYY')} - ${dayjs(project.dueDate).format('MMM DD, YYYY')}`;
              return {
                ...project,
                description: [timeLine, ...project.description],
              };
            });
            resolve({ data: formattedResponse });
          }, 1000);
        });
      },
    }),
    getDraftProjects: builder.query<IProjectApiObject[], void>({
      queryFn: async () => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const response = await getDraftDetails();
            const formattedResponse = response.map((project: any) => {
              const timeLine = `${dayjs(project.startDate).format('MMM DD, YYYY')} - ${dayjs(project.dueDate).format('MMM DD, YYYY')}`;
              return {
                ...project,
                description: [timeLine, ...project.description],
              };
            });
            resolve({ data: formattedResponse });
          }, 1000);
        });
      },
    }),
    getUsers: builder.query<IUser[], void>({
      queryFn: async () => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const users = await fetchUsers();
            resolve({ data: users });
          }, 1000);
        });
      },
    }),
    getGlobalSettings: builder.query<any, void>({
      queryFn: async () => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const settings = await getGlobalProjectSettings();
            resolve({ data: settings });
          }, 1000);
        });
      },
    }),
    getProjectDetail: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const settings = await getProjectDetail(arg.projectId);
            resolve({ data: settings });
          }, 1000);
        });
      },
    }),
    getProjectNotes: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const notes = await getProjectNotes(arg.projectId);
            resolve({ data: notes });
          }, 1000);
        });
      },
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetUsersQuery,
  useGetGlobalSettingsQuery,
  useGetProjectDetailQuery,
  useGetProjectNotesQuery,
  useGetDraftProjectsQuery,
} = apiSlice;
