import { useDispatch, useSelector } from "react-redux";
import MissingUploadBtn from "../missing-upload-button/missing-upload-button"
import RadialProgress from "../radial-progress/radial-progress"
import { RootState } from "../../redux/store";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { UploadFile } from "../upload-list/upload-list.types";
import SVGTrash from "../../assets/images/svg/trash.icon";
import { SharedService } from "../../services/shared-service";
import { setActiveModal, setControlCardData, setDynamicCallData, setProcData, setProgramData, setUploadedMissingArtifacts } from "../../redux/app-global";
import { externalConfig } from "../../utils/misc.utils";
import axios from "axios";
import { EAppModal, IUploadedArtifacts, TableData } from "../../redux/app-global/app-global.types";
import HelpTextCallout from "../help-text-callout/help-text-callout";


interface UploadMissingListProps {
    setData: React.Dispatch<React.SetStateAction<TableData[]>>;
    setMissingArtifacts?: React.Dispatch<React.SetStateAction<IUploadedArtifacts>>;
    onFilesUpdate?: any;
    type:string;
    data:TableData[];
    pageHelpText?: any;
  }
  export interface UploadMissingListRef {
    handleFileUpload: (
      event: React.ChangeEvent<HTMLInputElement>,
      itemKey: any
    ) => Promise<void>;
    getFiles: () => Record<string, UploadFile>;
  }
  const UploadMissingList = forwardRef<UploadMissingListRef, UploadMissingListProps>(
    ({ setData, data, setMissingArtifacts, type,pageHelpText, onFilesUpdate }, ref) => {
    const dispatch = useDispatch();
    const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);
    const uploadedMissingArtifacts = useSelector((state: RootState) => state.appGlobal.uploadedMissingArtifacts)
    const license = useSelector((state: RootState) => state.appGlobal.license);
    const [progress, setProgress] = useState<number>(0);
    const [zipFileStatus, setZipFileStatus] = useState<Record<string, any>>({});
    const [fileStatus, setFileStatus] = useState<Record<string, any>>({});
    const [files, setFiles] = useState<Record<string, UploadFile>>({});
    useEffect(() => {
        setFiles(uploadedMissingArtifacts?.artifacts || {});
        setZipFileStatus(uploadedMissingArtifacts?.zipFileStatus || {});
        setFileStatus(uploadedMissingArtifacts?.fileStatus || {})
    }, []);
    function extractSerializableFileProperties(
        files: Record<string, UploadFile>,
      ): Record<string, any> {
        const serializableFiles: Record<string, any> = {};
    
        Object.keys(files).forEach(key => {
          const file = files[key];
          const serializableFile = {
            ...file,
            originalFile: { size: file.originalFile.size, name: file.originalFile.name },
          };
          serializableFiles[key] = serializableFile;
        });
        return serializableFiles;
    }
    const removeFile = (fileName: string) => {
        const url = `${externalConfig.REACT_APP_API_URL}/file/delete`;
        axios
          .post(url, { fileName: fileName })
          .then(res => {
            const newFiles = { ...files };
            
            setData((prevItems: any) => {
                const updatedItems = prevItems.map(item =>
                    item.uploaded === fileName ? { ...item, uploaded: null } : item,
                );
                dispatch(setProcData(updatedItems));            
                return updatedItems;
              });
            delete newFiles[fileName];
            setFiles(newFiles);
            onFilesUpdate(newFiles);
            setZipFileStatus(prevZipFileStatus => {
              const updatedZipFileStatus = { ...prevZipFileStatus, [fileName]: null };
      
              // Then, update `fileStatus`
              setFileStatus(prevFileStatus => {
                const updatedFileStatus = { ...prevFileStatus };
                delete updatedFileStatus[fileName];
                if(setMissingArtifacts)
                  setMissingArtifacts({
                    artifacts: extractSerializableFileProperties(newFiles),
                    missingFiles: null,
                    dependentFiles: null,
                    zipFileStatus: updatedZipFileStatus,
                    fileStatus: updatedFileStatus,
                  })      
                return updatedFileStatus; 
              });      
              return updatedZipFileStatus;
            });
          })
          .catch(console.log);
      };
    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        itemKey: any
      ) => {
        const fileSet = event.target.files;
        if (!fileSet) {
          event.preventDefault();
          event.target.value = '';
          return;
        }
    
        const newFiles = { ...files };
        const totalSize =
          Array.from(fileSet).reduce(
            (acc: number, file: File) => acc + file.size,
            0,
          ) +
          Object.values(files).reduce(
            (acc, file) => acc + file.originalFile.size,
            0,
          );
    
        const limit = license?.fileUploadLimit || 100;
        const maxFilesSize = limit * 1024 * 1024;
        if (totalSize > maxFilesSize) {
          event.target.value = '';
          dispatch(setActiveModal(EAppModal.FILE_SIZE_LIMIT_EXCEEDED));
          return;
        }
        if (fileSet && fileSet.length) {
          for (let i = 0; i < fileSet.length; i++) {
            const file = fileSet[i];
            try {
              const fileInfo = getUploadFileInfo(file);
              // await uploadFile(fileInfo);
              setZipFileStatus(prev => ({
                ...prev,
                [file.name]: {"status": "PENDING"},
              }));
              setFileStatus(prev => ({
                ...prev,
                [file.name]: {"status": "PENDING"},
              }));
              newFiles[file.name] = fileInfo;
              setFiles(newFiles);
              onFilesUpdate(newFiles);
              SharedService.reportAPICount = 0;
              if(setMissingArtifacts){                
                setMissingArtifacts({
                  artifacts: extractSerializableFileProperties(newFiles),
                  missingFiles:[],
                  dependentFiles:[],
                  zipFileStatus: { ...zipFileStatus, [file.name]: {"status": "PENDING"} },
                  fileStatus: {...fileStatus, [file.name] : {"status": "PENDING"}},
            })  
              }                      
              setData((prevItems: any) => {
                const updatedItems = prevItems.map(item =>
                    item.key === itemKey ? { ...item, uploaded: file.name } : item,
                );
                return updatedItems;
              });
            } catch (err) {
              console.log(err);
              dispatch(setActiveModal(EAppModal.FILE_UPLOAD_FAILED));
              setProgress(0);
              return;
            } finally {
              setProgress(0);
            }
          }
        }
        event.target.value = '';
      };
      const getUploadFileInfo = (file: any): UploadFile => {
        const fileInfo: UploadFile = {
          name: file.name,
          size: formatFileSize(file.size),
          type: getFileType(file),
          originalFile: file,
          uploadProgress: 0,
          error: '',
        };
        return fileInfo;
      };
      async function uploadFile(file: UploadFile) {
        SharedService.genericModalMessage = '';
        if (file && !file.error) {
          try {
            const formData = new FormData();
            const url = `${externalConfig.REACT_APP_API_URL}/file/upload`;
            formData.append('file', file.originalFile);
            const response = await axios.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              timeout: -1,
              onUploadProgress(progressEvent) {
                if (progressEvent.total) {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  );
                  setProgress(percentCompleted);
                }
              },
            });
    
            if (response.data.status === 'ERROR') {
              throw new Error(response.data.message);
            }
          } catch (error: any) {
            (SharedService.genericModalMessage =
              error.message ??
              'It looks like we need more analysis to process the file.'),
              setProgress(0);
            throw error;
          }
        } else {
          throw new Error('File not found');
        }
      }
      const formatFileSize = (size: number) => {
        return size < 1024
          ? `${size} B`
          : size < 1048576
            ? `${(size / 1024).toFixed(2)} KB`
            : size < 1073741824
              ? `${(size / 1048576).toFixed(2)} MB`
              : `${(size / 1073741824).toFixed(2)} GB`;
      };
      const getFileType = (file: any) => {
        return file.name.includes('.')
          ? file.name.split('.').pop().toUpperCase()
          : '';
      };
      useImperativeHandle(ref, () => ({
        handleFileUpload,
        getFiles: () => files,
      }));
    return (
        <div>
            <h2 className="flex items-center justify-center font-bold mb-4">OR</h2>
            <hr />
            <div className="flex flex-col gap-4 mt-4 mb-2 border rounded-lg p-4 px-2 shadow">
                <div className="flex flex-row gap-2 items-center">
                  <HelpTextCallout calloutText={pageHelpText[5]?.description}/> 
                  Upload the missing {type} bundled inside a ZIP or XMI file.
                </div>
                <div className="flex flex-row gap-4 items-center">
                    <div className='mt-4'>
                        <MissingUploadBtn
                            handleFileUpload={handleFileUpload}
                            accept=".zip,.xmi"
                        />
                    </div>
                    {progress > 0 && (
                        <div className="-ml-2 mt-[4px] flex flex-row gap-2 items-center">
                            <RadialProgress total={100} currentProgress={progress} />
                            <p className="text-[14px]">
                                {progress != 100 && 'Uploading Files'}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {Object.values(files).length > 0 && (
                <div className="overflow-auto mt-4">
                  <h2 className="px-4 py-4 font-semibold">Uploaded Artifacts: </h2>
                    {Object.values(files).map((file: UploadFile, index: number) => (
                        <div
                            key={index}
                            className={`mb-2 border rounded-lg p-4 px-2 shadow`}
                            style={{
                                borderWidth: 1,
                            }}
                        >
                            <div className="grid grid-cols-12 items-center">
                                <div className="ml-2 h-10 w-10 flex items-center justify-center bg-gray-200 rounded-lg text-gray-700">
                                    {file.type}
                                </div>
                                <div className="col-span-5 flex flex-col items-start">
                                    <div className="flex-grow truncate text-[14px] font-semibold">
                                        {file.name}
                                    </div>
                                    <div className="w-24 text-[14px]">{file.size}</div>
                                </div>
                                <div className="col-span-5"></div>
                                <div className="flex justify-end mr-4">
                                    <button className="ml-2 text-gray-700 hover:text-gray-900">
                                        <span className="text-red-500">
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => removeFile(file.name)}
                                            >
                                                <SVGTrash />
                                            </div>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
});

export default UploadMissingList;
