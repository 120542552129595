import { useState } from 'react';
import { createPortal } from 'react-dom';
import { ICreateNewUserProps } from './create-new-user.types';
import Swal from 'sweetalert2';

const CreateNewUserModal = ({ onClose, onSubmit }: ICreateNewUserProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return false;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const error = validateForm();
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Form is invalid ',
      });
      return;
    }
    onSubmit({ name, email, password });
  };

  return createPortal(
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        inset: 0,
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: '10%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          overflow: 'auto',
          minWidth: '35rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: window.innerHeight * 0.95,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          padding: '2rem',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        <div>
          <h2
            style={{
              fontSize: '1.25rem',
              fontWeight: '500',
              color: '#374151',
            }}
          >
            Create New User
          </h2>
          <button
            style={{
              border: '0',
              color: 'black',
              float: 'right',
              fontSize: '5rem',
              lineHeight: 'none',
              fontWeight: 'medium',
              outline: 'none',
              transition: 'all',
              cursor: 'pointer',
              position: 'absolute',
              top: 0,
              right: '1rem',
              background: 'transparent',
              borderWidth: 0,
            }}
            onClick={onClose}
          >
            <span
              style={{
                color: 'black',
                height: '1.5rem',
                width: '1.5rem',
                fontSize: '2.5rem',
                display: 'block',
                outline: 'none',
              }}
            >
              ×
            </span>
          </button>
          <hr style={{ margin: '.5rem 0 1rem' }} />
          <p
            style={{
              marginBottom: '1rem',
              lineHeight: '1.5',
              color: '#374151',
            }}
          >
            Fill out the form to create a new user account.
          </p>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                lineHeight: '1.5',
                color: '#374151',
              }}
            >
              <div
                style={{
                  marginBottom: '.5rem',
                  lineHeight: '1.5',
                  color: '#374151',
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Name"
                  onChange={e => setName(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    height: 'unset',
                    fontWeight: '400',
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '.5rem',
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="User Email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    height: 'unset',
                    fontWeight: '400',
                  }}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: '.5rem',
              }}
            >
              <label
                htmlFor="password"
                style={{
                  fontSize: '0.875rem',
                  lineHeight: '1.75',
                  color: '#4a5568',
                  marginBottom: 0,
                }}
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                  width: '100%',
                  borderRadius: '0',
                  border: '1px solid #ccc',
                  backgroundColor: '#fff',
                  padding: '0.5rem 0.75rem',
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  color: '#333',
                  outline: 'none',
                  transition: 'color 0.2s ease-in-out',
                  height: 'unset',
                  fontWeight: '400',
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                marginTop: '2rem',
              }}
            >
              <button
                type="submit"
                style={{
                  borderRadius: 4,
                  backgroundColor: 'rgb(19,98,223)',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                  fontSize: '1.1rem',
                  color: '#fff',
                  borderWidth: 0,
                }}
              >
                Send
              </button>
              <button
                style={{
                  background: 'transparent',
                  color: 'black',
                  borderWidth: 0,
                }}
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default CreateNewUserModal;
