import React, { useState } from 'react';
import Dropdown from 'src/components/custom-dropdown/custom-dropdown';

const Drafts = () => {
  const [useLLM, setUseLLM] = useState('None');
  const [openAIKey, setOpenAIKey] = useState('');

  const options = ['None', 'OpenAI', 'Azure'];

  return (
    <>
      <div className="p-1 min-h-screen">
        <div className="max-w-3xl bg-white shadow-md rounded-lg p-6">
          <label className="block text-md font-medium mb-2">Select LLM</label>
          <Dropdown
            options={options}
            selectedOption={useLLM}
            onOptionSelect={setUseLLM}
          />
          {useLLM === 'OpenAI' && (
            <div className="mt-6">
              <label className="block text-md font-medium mb-2">
                OpenAI Key
              </label>
              <input
                type="text"
                value={openAIKey}
                onChange={e => setOpenAIKey(e.target.value)}
                className="border rounded p-2 w-full"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Drafts;
