import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal } from '../../redux/app-global';
import axios from 'axios';
import SVGSuccess from '../../assets/images/svg/success.icon';
import {
  setFormSubmitted,
  setUserDetails,
} from '../../redux/app-global/app-global';
import { RootState } from '../../redux/store';
import { AsYouType } from 'libphonenumber-js';
import {
  externalConfig,
  isValidInternationalPhoneNumber,
  apiConfig,
} from '../../utils/misc.utils';
import useRecaptcha from '../../hooks/useRecapcha';
import { isCorporateEmailDomain } from '../../utils/form-validator.utils';
import ReCAPTCHA from '../recapcha/recapcha';
import { createPortal } from 'react-dom';
import { triggerOtpForEmail } from '../assisted-service/assisted-service.service';
import VerifyEmail from '../verify-email/verify-email';

const AccessFullReport = () => {
  const [name, setName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );

  useEffect(() => {
    if (userDetails) {
      setName(userDetails.name);
      setCompanyEmail(userDetails.companyEmail);
      setPhoneNumber(userDetails.phoneNumber);
    }
  }, [userDetails]);

  const dispatch = useDispatch();

  function validateForm() {
    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name.trim().length === 0) {
      return "Name can't be empty";
    }

    if (!/^[a-zA-Z ]+$/.test(name)) {
      return 'Invalid name';
    }
    if (companyEmail.trim().length === 0) {
      return "Company email can't be empty";
    }
    if (!EMAIL_REGEX.test(companyEmail)) {
      return 'Invalid email';
    }
    if (!isCorporateEmailDomain(companyEmail)) {
      return 'Invalid email, Please enter your corporate email';
    }
    if (phoneNumber) {
      if (!phoneNumber.startsWith('+')) {
        return 'Country code must be prefixed with "+"';
      }
      if (!isValidInternationalPhoneNumber(phoneNumber)) {
        return 'Invalid phone number';
      }
    }

    return '';
  }

  async function handleSubmission(e: any) {
    e.preventDefault();
    setLoading(true);

    const error = validateForm();
    if (error) {
      setLoading(false);
      alert(error);
      return;
    }

    try {
      const otpRes = await triggerOtpForEmail(companyEmail);

      if (
        otpRes.status === 'SUCCESS' &&
        otpRes.message === 'Email is already validated'
      ) {
        return await handleSubmit('');
      } else if (otpRes.status === 'SUCCESS') {
        setStep(2);
      } else {
        alert(otpRes.message || 'Something went wrong. Please try again.');
      }
    } catch (err: any) {
      console.log(err);
      alert(err?.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (otp: string, token?: string) => {
    const payload = {
      name,
      email: companyEmail,
      phone: phoneNumber === '+' ? '' : phoneNumber,
      gtoken: token || capchaToken,
      otp: otp ? parseInt(otp) : 0,
    };
    const result = await axios.post(
      `${externalConfig.REACT_APP_API_URL}/user/registration`,
      payload,
    );

    if (
      result.data.recaptchaValid === false ||
      result?.data.message === 'Captcha is not valid'
    ) {
      alert('ReCAPTCHA validation failed. Please try again.');
      handleRecaptcha('');
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setLoading(false);

      if (token) {
        return 'Invalid Capcha';
      }
      return;
    }

    if (result?.data?.message === 'OTP is not valid') {
      alert('OTP is not valid. Please try again.');
      setLoading(false);
      return;
    }

    if (result.data.status === 'SUCCESS') {
      dispatch(
        setUserDetails({
          name,
          companyEmail,
          phoneNumber,
        }),
      );
      setName('');
      setCompanyEmail('');
      setPhoneNumber('');
      setSubmitted(true);
      dispatch(setFormSubmitted(true));
    }
  };

  function handleClose() {
    dispatch(setActiveModal(null));
  }

  async function handleResend() {
    setLoading(true);
    try {
      const otpRes = await triggerOtpForEmail(companyEmail);
      if (otpRes.status !== 'SUCCESS') {
        alert(otpRes.message || 'Something went wrong. Please try again.');
      }
      return true;
    } catch (err: any) {
      console.log(err);
      alert(err?.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  function handleChangeEmail() {
    setStep(1);
  }

  async function handleVerify(otp: string, token?: string) {
    setLoading(true);
    try {
      return await handleSubmit(otp, token);
    } catch (err: any) {
      console.log(err);
      alert(err?.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return createPortal(
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        inset: 0,
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: '10%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          overflow: 'auto',
          maxWidth: '35rem',
          maxHeight: window.innerHeight * 0.95,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          padding: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {submitted ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem',
              paddingTop: '1.5rem',
            }}
          >
            <div>
              <SVGSuccess />
            </div>
            <div
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                marginTop: '1rem',
              }}
            >
              <p style={{ fontSize: '1.5rem', fontWeight: '600' }}>
                Successfully submitted{' '}
              </p>
            </div>
            <div style={{ marginTop: '.5rem' }}>
              <p style={{ fontSize: '1rem' }}>
                Thank you for providing your information. We&apos;ll send the
                full report to the email you provided shortly.
              </p>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4',
                  marginTop: '1.5rem',
                }}
              >
                <button
                  style={{
                    borderRadius: '0.5rem',
                    border: '0',
                    backgroundColor: 'rgb(19,98,223)',
                    padding: '0.5rem 1rem',
                    fontSize: '1.2rem',
                    color: '#fff',
                    cursor: 'pointer',
                    borderWidth: 0,
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '500',
                color: '#374151',
              }}
            >
              Access Full Report
            </h2>
            <button
              style={{
                border: '0',
                color: 'black',
                float: 'right',
                fontSize: '5rem',
                lineHeight: 'none',
                fontWeight: 'medium',
                outline: 'none',
                transition: 'all',
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                right: '1rem',
                borderWidth: 0,
                background: 'transparent',
              }}
              onClick={handleClose}
            >
              <span
                style={{
                  color: 'black',
                  height: '1.5rem',
                  width: '1.5rem',
                  fontSize: '2.5rem',
                  display: 'block',
                  outline: 'none',
                }}
              >
                ×
              </span>
            </button>
            <hr style={{ margin: '1rem 0 1rem' }} />
            {step === 1 && (
              <div>
                <p
                  style={{
                    marginBottom: '1rem',
                    lineHeight: '1.5',
                    color: '#374151',
                  }}
                >
                  Unlock the full report by providing your details. Your
                  information is crucial in ensuring the report reaches the
                  right hands. Once you have submitted your details,{' '}
                  <strong>
                    we will send the full report to the email you have provided.
                  </strong>
                </p>
                <form onSubmit={handleSubmission}>
                  <div
                    style={{
                      lineHeight: '1.5',
                      color: '#374151',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '.5rem',
                        lineHeight: '1.5',
                        color: '#374151',
                      }}
                    >
                      <label
                        htmlFor="email"
                        style={{
                          fontSize: '0.875rem',
                          lineHeight: '1.75',
                          color: '#4a5568',
                          marginBottom: 0,
                        }}
                      >
                        Name *
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Your Name"
                        onChange={e => setName(e.target.value)}
                        style={{
                          width: '100%',
                          borderRadius: '0',
                          border: '1px solid #ccc',
                          backgroundColor: '#fff',
                          padding: '0.5rem 0.75rem',
                          fontSize: '1rem',
                          lineHeight: '1.5',
                          color: '#333',
                          outline: 'none',
                          transition: 'color 0.2s ease-in-out',
                          height: 'unset',
                          fontWeight: '400',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: '.5rem',
                      }}
                    >
                      <label
                        htmlFor="companyEmail"
                        style={{
                          fontSize: '0.875rem',
                          lineHeight: '1.75',
                          color: '#4a5568',
                          marginBottom: 0,
                        }}
                      >
                        Company Email *
                      </label>
                      <input
                        type="email"
                        id="companyEmail"
                        name="companyEmail"
                        placeholder="Company Email"
                        value={companyEmail}
                        onChange={e => setCompanyEmail(e.target.value)}
                        style={{
                          width: '100%',
                          borderRadius: '0',
                          border: '1px solid #ccc',
                          backgroundColor: '#fff',
                          padding: '0.5rem 0.75rem',
                          fontSize: '1rem',
                          lineHeight: '1.5',
                          color: '#333',
                          outline: 'none',
                          transition: 'color 0.2s ease-in-out',
                          height: 'unset',
                          fontWeight: '400',
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: '.5rem',
                      marginBottom: '1rem',
                      lineHeight: '1.5',
                      color: '#374151',
                    }}
                  >
                    <label
                      htmlFor="phoneNumber"
                      style={{
                        fontSize: '0.875rem',
                        lineHeight: '1.75',
                        color: '#4a5568',
                        marginBottom: 0,
                      }}
                    >
                      Phone Number
                    </label>
                    <input
                      type="phoneNumber"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={phoneNumber}
                      placeholder="Your Phone Number (e.g. +1 123 456 7890)"
                      onChange={e => {
                        setPhoneNumber(
                          new AsYouType().input(
                            !e.target.value || e.target.value.startsWith('+')
                              ? e.target.value
                              : `+${e.target.value}`,
                          ),
                        );
                      }}
                      style={{
                        width: '100%',
                        borderRadius: '0',
                        border: '1px solid #ccc',
                        backgroundColor: '#fff',
                        padding: '0.5rem 0.75rem',
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        color: '#333',
                        outline: 'none',
                        transition: 'color 0.2s ease-in-out',
                        height: 'unset',
                        fontWeight: '400',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      minHeight: '78px',
                    }}
                  >
                    <ReCAPTCHA ref={recaptchaRef} onChange={handleRecaptcha} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                      marginTop: '1rem',
                    }}
                  >
                    <button
                      type="submit"
                      disabled={loading || !capchaToken}
                      style={{
                        borderRadius: 4,
                        backgroundColor: 'rgb(19,98,223)',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                        fontSize: '1.1rem',
                        color: '#fff',
                        borderWidth: 0,
                        opacity: loading || !capchaToken ? 0.5 : 1,
                      }}
                    >
                      Submit
                    </button>
                    <button
                      style={{
                        background: 'transparent',
                        color: 'black',
                        borderWidth: 0,
                      }}
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            )}
            {step === 2 && (
              <div>
                <VerifyEmail
                  email={companyEmail}
                  loading={loading}
                  handleResend={handleResend}
                  handleVerify={handleVerify}
                  handleChangeEmail={handleChangeEmail}
                  capchaToken={capchaToken}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};

export default AccessFullReport;
