import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useMemo } from 'react';
import { EArtifactStatus } from '../../../../redux/app-global/app-global.types';

const Summary = () => {
  const artifactStatus = useSelector(
    (state: RootState) => state.appGlobal.artifactStatus || {},
  );
  const statuses = useMemo(() => {
    let transformSuccessFullCount = 0;
    let transformInProgressCount = 0;
    let transformFailedCount = 0;

    Object.keys(artifactStatus).forEach(key => {
      if (artifactStatus[key] === EArtifactStatus.SUCCESS) {
        transformSuccessFullCount++;
      } else if (artifactStatus[key] === EArtifactStatus.INPROGRESS) {
        transformInProgressCount++;
      } else if (artifactStatus[key] === EArtifactStatus.ERROR) {
        transformFailedCount++;
      }
    });

    return {
      transformSuccessFullCount,
      transformInProgressCount,
      transformFailedCount,
    };
  }, [artifactStatus]);

  return (
    <div className="grid grid-cols-3 gap-4">
      <div
        className="border text-center bg-greyLight text-primary-foreground rounded-lg shadow-sm"
        data-v0-t="card"
      >
        <div className="p-6 text-black">
          <p className="text-lg font-medium mb-2 text-black">
            Transformed Successfully
          </p>
          <div className="text-4xl font-bold text-black">
            {statuses.transformSuccessFullCount}
          </div>
        </div>
      </div>
      <div
        className="border text-center bg-secondary text-secondary-foreground rounded-lg shadow-sm"
        data-v0-t="card"
      >
        <div className="p-6 bg-greyLight">
          <p className="text-lg font-medium mb-2">Transformation In Progress</p>
          <div className="text-4xl font-bold">
            {statuses.transformInProgressCount}
          </div>
        </div>
      </div>
      <div
        className="border text-center bg-greyLight text-black rounded-lg shadow-sm"
        data-v0-t="card"
      >
        <div className="p-6">
          <p className="text-lg font-medium mb-2 text-black">
            Transformation Failed
          </p>
          <div className="text-4xl font-bold text-black">
            {statuses.transformFailedCount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
