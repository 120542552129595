import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export const SupportTicketTable = () => {
  const supportTicketsGenerated = useSelector(
    (state: RootState) => state.appGlobal.supportTicketsGenerated || [],
  );
  return (
    <table className="full bg-white border-collapse border border-gray-200 w-1/2 mt-4 ml-4 shadow-md rounded-lg">
      <thead>
        <tr>
          <th className="py-2 px-4 sm:px-6 border-b text-left text-sm font-semibold text-gray-700 uppercase tracking-wider bg-gray-100">
            Ticket
          </th>
          <th className="py-2 px-4 sm:px-6 border-b text-left text-sm font-semibold text-gray-700 uppercase tracking-wider bg-gray-100">
            Title
          </th>
        </tr>
      </thead>
      <tbody>
        {supportTicketsGenerated.map((row, index) => (
          <tr key={index}>
            <td className="py-2 px-4 sm:px-6 border-b border-gray-300 text-sm text-gray-600">
              {row.id}
            </td>
            <td className="py-2 px-4 sm:px-6 border-b border-gray-300 text-sm text-gray-600">
              {row.title}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
