import { Steps } from 'antd';
import OverviewCards from '../overview-cards';
import { appTheme } from '../../../../styles/theme';
import { CiPlay1 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import AdminOnly from 'src/components/admin-only/admin-only';
import UserOnly from 'src/components/user-only/user-only';

const stages = [
  {
    title: 'Extract',
  },
  {
    title: 'Upload',
  },
  {
    title: 'Readiness',
  },
  {
    title: 'Transform',
  },
  {
    title: 'Enhance',
  },
  {
    title: 'Test',
  },
  {
    title: 'Deploy',
  },
];
const StagesComponent = () => {
  const navigate = useNavigate();

  const resumeProject = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className="flex-grow">
      <OverviewCards height="303px">
        <div className="relative flex flex-col gap-8 w-full h-full overflow-hidden">
          <Steps
            items={stages}
            size="small"
            labelPlacement="vertical"
            current={0}
          />
          <h3 className="font-semibold mx-4">
            Current Stage:{' '}
            <span className="ml-8 font-light">{stages[0].title}</span>
          </h3>
          <h3 className="font-semibold mx-4">
            Status:{' '}
            <span
              className={`ml-16 text-sm px-4 py-1 rounded-full`}
              style={{
                color: appTheme.colors.greenBase,
                background: 'rgba(219,244,210,.8)',
              }}
            >
              Ongoing
            </span>
          </h3>
          <UserOnly>
            <button
              onClick={resumeProject}
              className="bottom-0 right-0 absolute text-cta text-xl font-semibold hover:text-blue-800 flex items-center gap-2"
            >
              Resume{' '}
              <span className="mt-0.5">
                <CiPlay1 />
              </span>
            </button>
          </UserOnly>
        </div>
      </OverviewCards>
    </div>
  );
};
export default StagesComponent;
