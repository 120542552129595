import { Steps } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
const titleMapping = {
    RESOLVE_DYNAMIC_CALLS: 'Resolve Dynamic Calls',
    MISSING_PROGRAM: 'Resolve Missing Programs',
    MISSING_CONTROLCARD: 'Resolve Missing Control Cards',
    MISSING_PROC: 'Resolve Missing Procs',
    MISSING_ARTIFACTS: 'Resolve Missing Symbols',
    MISSING_METADATA: 'Resolve Missing Metadata'
}
const StepperComponent = () => {
    const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);
    const currentStep = missingPages.findIndex(page => page.status === true);
    const stepsItems = missingPages.map((page, index) => ({
        title: titleMapping[page.key] || page.key,
    }));

    return (
        <Steps current={currentStep} items={stepsItems}/>
    );
}
export default StepperComponent;