import React, { useState } from 'react';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { Switch } from '../../../../src/components/ui/switch'; 

const TransformationSettings = () => {
    const initialSettings = [
        { name: "Abend Code", defaultValue: "4321" },
        { name: "Package Namespace", defaultValue: "com.cloudframe.app" },
        { name: "Cleanup Compile Warnings", defaultValue: "Yes" },
        { name: "Compile Java", defaultValue: "No" },
        { name: "Generate Control Advice", defaultValue: "Yes" },
        { name: "Generate Custom Sqlca", defaultValue: "No" },
        { name: "Generate Interface", defaultValue: "Yes" },
        { name: "Generate Method Context", defaultValue: "Yes" },
        { name: "Generate Program Regardless Of Hash", defaultValue: "No" },
        { name: "Generate With Annotations", defaultValue: "No" },
    ];

    const [settings, setSettings] = useState(initialSettings);

    const handleToggle = (index: number) => {

        const updatedSettings = [...settings];
        if (updatedSettings[index].defaultValue === "Yes") {
            updatedSettings[index].defaultValue = "No";
        } else {
            updatedSettings[index].defaultValue = "Yes";
        }
        setSettings(updatedSettings);
    };

    const handleNamespaceChange = (index: number, newValue: string) => {
        const updatedSettings = [...settings];
        updatedSettings[index].defaultValue = newValue;
        setSettings(updatedSettings);
    };


    return (
        <div className="p-1 bg-gray-100 flex justify-left items-left">
            <div className="bg-white shadow-2xl rounded-lg p-8 w-full max-w-4xl">
                <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-col">
                        <label className="block text-md font-medium mb-2 flex items-center">
                            Abend Code
                            <HelpTextCallout calloutText={''}/>
                        </label>
                        <input 
                            type="text" 
                            value={settings[0].defaultValue} 
                            className="w-full p-2 bg-black text-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    
                    <div className="flex flex-col">
                        <label className="block text-md font-medium mb-2 flex items-center">
                            Package Namespace
                            <HelpTextCallout calloutText={''}/>
                        </label>
                        <input 
                            type="text" 
                            value={settings[1].defaultValue}
                            onChange={(e) => handleNamespaceChange(1, e.target.value)}
                            className="w-full p-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    {settings.slice(2).map((setting, index) => (
                        <div className="flex flex-col" key={index + 2}>
                            <label className="block text-md font-medium mb-2 flex items-center">
                                {setting.name}
                                <HelpTextCallout calloutText={''}/>
                            </label>
                            <div className="flex items-center">
                                <span 
                                    className={`mr-3 ${
                                        setting.defaultValue === "Yes" ? "font-bold" : "font-medium"
                                    }`}
                                >
                                    Yes
                                </span>
                                <Switch
                                    checked={setting.defaultValue === "Yes"}
                                    onCheckedChange={() => handleToggle(index + 2)}
                                    className="mx-2"
                                />
                                <span 
                                    className={`ml-3 ${
                                        setting.defaultValue === "No" ? "font-bold" : "font-medium"
                                    }`}
                                >
                                    No
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TransformationSettings;
