import React, { useState } from 'react';
import Tabs from './header-navigation/header-navigation';
import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';

const ProjectTransformationSettings = () => {
  const [activeTab, setActiveTab] = useState('Transformation');

  return (
    <div className="p-4 flex flex-row bg-[#F3F4F4] h-[100vh]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <ProjectSideBar activeScreen={'Settings'} />
      </div>
      <div className="mt-4 ml-8">
        <div>
          <h1 className="text-2xl font-bold">
            Project Transformation Settings
          </h1>
          <p className="mt-4">
            Configure and customize transformation settings for your project.
            Adjust parameters and preferences to ensure accurate and efficient
            COBOL to Java conversion.
          </p>
          <div className="mt-8">
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTransformationSettings;
