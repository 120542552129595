const MobileNotSupported = () => (
  <div className="flex flex-col items-center justify-center bg-gray-600 text-white h-screen w-full flex-grow">
    <div className="flex flex-col rounded-md items-center justify-center bg-gray-500 text-center p-4 m-4">
      <div className="mb-4">
        <img
          src="https://cf1.cloudframe.com/wp-content/uploads/2022/03/CF-Logo-White.png"
          alt="CloudFrame Logo"
          style={{ marginBlock: '10px' }}
        />
      </div>
      <div className="m-3 mt-2 mb-4">
        <p className="text-white text-lg">
          Sorry, this site is not supported in Mobile device
        </p>
      </div>
      <div>
        <p className="text-white text-lg">
          Please try again on your Desktop/Laptop device ...
        </p>
      </div>
    </div>
  </div>
);

export default MobileNotSupported;
