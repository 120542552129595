import { useDispatch } from 'react-redux';
import { setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';
import './chevron.css';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export interface IChevronItemProps {
  text: string;
  title?: string;
  intent: EAppIntent | null;
  isActive?: boolean;
  info?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export interface IChevronProps {
  items: IChevronItemProps[];
  style?: React.CSSProperties;
}

export default function Chevron(props: IChevronProps) {
  const dispatch = useDispatch();

  const onChevronItemClick = (item: IChevronItemProps) => {
    item.onClick?.();
    if (item.intent) {
      dispatch(setCurrentIntent(item.intent));
    }
  };

  return (
    <div
      className="flex gap-2 justify-center items-center shadow-sm p-4 bg-gradient-to-r from-blue-900 to-blue-900 rounded-md"
      style={{ width: '100%', paddingRight: '2rem', ...props.style }}
    >
      {props.items.map((item, index) => (
        <div
          key={item.text}
          className={
            item.isActive ? 'chevron-container-active' : 'chevron-container'
          }
        >
          <div
            // title={item.title || ''}
            className={
              !item.intent
                ? 'chevron-left-disabled'
                : item.isActive
                  ? 'chevron-left-active'
                  : 'chevron-left'
            }
            style={{
              zIndex: props.items.length - index,
              ...item.style,
            }}
            onClick={() => onChevronItemClick(item)}
          >
            <div className="flex flex-row gap-0 items-center chevron-item">
              <span className="chevron-text">{item.text}</span>
              <ReactTooltip
                id="my-tooltip"
                place="bottom"
                positionStrategy="fixed"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontSize: '1rem',
                  maxWidth: '20rem',
                }}
              />
              <div
                data-tooltip-id="my-tooltip"
                data-tooltip-content={item.info}
              >
                <FaInfoCircle className="chevron-icon" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
