import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import { externalConfig } from '../../../utils/misc.utils';
import { setActiveModal, setCurrentIntent, setJCLCallData, setNextMissingPage, setNextMissingPageTest, setUploadedMissingArtifacts } from '../../../redux/app-global';
import { useDispatch, useSelector } from 'react-redux';
import { Artifact, EAppIntent, EAppModal, IUploadedArtifacts, TableData } from '../../../redux/app-global/app-global.types';

import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import {
    PrimaryButton,
} from '@fluentui/react';

import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { helpTexts } from '../../../helpTexts';
import { SharedService } from '../../../services/shared-service';

const pagHelpTexts = helpTexts[0].MISSING_ARTIFACTS;


const MissingArtifacts = () => {
    const [error, setError] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [tableValue, setTableValue] = useState<Artifact[]>([]);
    const [loading, setLoading] = useState(false);
    const jclData = useSelector((state: RootState) => state.appGlobal.jclData);
    const dispatch = useDispatch();
    const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);
    const [missingArtifacts, setMissingArtifacts] = useState<IUploadedArtifacts>();

    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/SYMBOL`;

            try {
                const response = await axios.get(apiUrl);
                const data = response.data?.data ?? response.data ?? [];
                if (Array.isArray(data)) {
                    setTableValue(data);
         
                } else {
                    setTableValue([]);
             
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
             
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const index = parseInt(e.target.dataset.index, 10);

        setTableValue(prevState => {
            const newState = [...prevState];
            newState[index] = { ...newState[index], symbolValue: value };
            return newState;
        });
    };
 
    const handleSubmission = async () => {
        const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/SYMBOL`;
        const formData = new FormData();

        const requestBody:any = [];

        tableValue.forEach(artifact => {
            const item = {
                name: artifact.name,
                value: artifact.symbolValue,
                referredIn: artifact.referredIn,
                status:"Active"
            };
            requestBody.push(item);
          });
      
          formData.append('missingData', JSON.stringify(requestBody));
        try {
            setLoading(true)
            const response = await axios.post(
                apiUrl,
                formData,
            );
            console.log(response, formData)
            if (response.data?.data) {
                setLoading(false)
                SharedService.genericAlertModal.title = 'Few Missing Artifacts not Uploaded!';
                SharedService.genericAlertModal.description =
                    'The Information which is necessary for complete transformation is not provided. Please provide all the missing artifacts information to get started with transformation!';
                setTimeout(() => {
                    dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
                }, 0);
                const data = response.data?.data ?? response.data ?? [];
                if (Array.isArray(data)) {
                    console.log(data)
                    setTableValue(data);
                } else {
                    setTableValue([]);
                }
                return;
            }
            console.log(tableValue)
            dispatch(setUploadedMissingArtifacts(missingArtifacts));
            dispatch(setJCLCallData(tableValue));
            const currentIndex = missingPages.findIndex((page) => page.status === true);
            if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
                const nextIndex = currentIndex + 1;
                const nextPage = missingPages[nextIndex];
                dispatch(setCurrentIntent(nextPage.key));
                dispatch(setNextMissingPage({ page: nextPage.key }));
                const updatedMissingPages = missingPages.map((page, index) =>
                    index === nextIndex ? { ...page, status: true }
                        : index === currentIndex ? { ...page, status: false }
                            : page
                );
                dispatch(setNextMissingPageTest(updatedMissingPages));
            } else {
                dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
                dispatch(setNextMissingPage({ page: undefined }));
            }
        } catch (err) {
            setLoading(false)
            console.log(err);
            SharedService.genericAlertModal.title = 'Upload of Missing Artfacts Failed!';
            SharedService.genericAlertModal.description =
                (err as any)?.message || 'Something went wrong !';
            setTimeout(() => {
                dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
            }, 0);
        }
    };

    const allowedExtensions = ["csv"];
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        if (e.target.files?.length) {
            const inputFile = e.target.files[0];

            const fileExtension = inputFile?.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                setError("Please input a CSV file");
                return;
            }
            setFile(inputFile);
            parseCSV(inputFile);
        }
    };

    const parseCSV = (file: File) => {
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const parsedData = results.data as Artifact[];
                const cleanedData = parsedData
                    .filter(artifact => artifact.name?.trim()) 
                    .map(artifact => ({
                        ...artifact,
                        referredIn: {
                            jcl: artifact.referredIn?.jcl || [],
                            proc: artifact.referredIn?.proc || [],
                        }
                    }));
                setTableValue(cleanedData);
            },
            error: (_error) => {
                setError("Error parsing CSV file");
            }
        });
    };
    

    const csvData = [
        ["name", "symbolValue"],
        ...tableValue.map(artifact => [artifact.name, artifact.symbolValue])
    ];

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const headers: React.ReactNode[] = [
        'Symbol Name',
        'Required By',
        'Symbol Value',
    ];

    const renderRowFields = (artifact: Artifact) => [
        artifact.name,
        [...(artifact.referredIn?.jcl || []), ...(artifact.referredIn?.proc || [])].join(', '),
        <input
            key={`missing_artifact_${artifact.name}`}
            type="text"
            name="symbolValue"
            data-index={tableValue.indexOf(artifact)}
            value={artifact.symbolValue}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-300 transition duration-300"
        />
    ];

    return (
        <>
            <div  className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}>
            <div className='p-2 px-8 mb-4'>
                    <StepperComponent />
                </div>
                <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
                <h1 className="text-3xl font-semibold mb-4">
                    Missing Artifacts Workspace
                </h1>
                <hr />
                <div className="flex flex-col gap-4">
                    <div className="text-left mt-4 gap-4 text-l font-semibold">
                        Missing JCL Symbols
                    </div>
                    <div>
                        <p>Please provide the below listed missing symbol details.</p>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-2">
                        <HelpTextCallout calloutText={pagHelpTexts[8].description}/>
                        <CSVLink
                            data={csvData}
                            filename={"missing-artifact.csv"}
                            className="cursor-pointer hover:text-blue-500 rounded transition duration-300 inline-block text-blue-700 text-sm"
                            
                        >
                            Download CSV Template
                        </CSVLink>
                        <HelpTextCallout calloutText={pagHelpTexts[9].description}/>
                        <label
                            htmlFor="csvUpload"
                            className="cursor-pointer hover:text-blue-500 rounded transition duration-300 inline-block text-blue-700 text-sm"
                        >
                            Upload CSV
                        </label>
                        <input
                            id="csvUpload"
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="hidden"
                        />
                    </div>
                        {tableValue.length > 0 ? (
                            <ReusableTable
                                headers={headers}
                                items={tableValue}
                                rowFields={renderRowFields}
                                helpTexts={pagHelpTexts}
                            />
                        ) : (
                            <>
                                <ReusableTable
                                    headers={headers}
                                    items={tableValue.length > 0 ? tableValue : []}
                                    rowFields={tableValue.length > 0 ? renderRowFields : () => []}
                                    helpTexts={pagHelpTexts}
                                />
                                <p className="text-gray-600">No data available</p>
                            </>
                        )}
                    <div className="flex">
                        <PrimaryButton
                            onClick={handleSubmission}
                            text={'Save and Continue'}
                            className="mt-8 rounded-md"
                            styles={{ root: { backgroundColor: '#1363DF' } }}
                            disabled={loading}
                        />
                    </div>
                        {loading && (
                            <div className='flex gap-2 mt-4'>
                                <FaSpinner className="animate-spin" />
                                <p>Updating Missing data ....</p>
                            </div>
                        )}
                </div>
            </div>
            </div>
        </>
    );
};

export default MissingArtifacts;
