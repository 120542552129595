import { useUser } from 'src/hooks/useUser';
import { IAdminWrapperProps } from './admin-wrapper.types';
import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';

const AdminWrapper: React.FC<IAdminWrapperProps> = ({
  children,
}: IAdminWrapperProps) => {
  const user = useUser();

  if (user?.role === 'Member') {
    return <div>{children}</div>;
  }

  return (
    <div className="p-4 pt-0 flex flex-row bg-[#F3F4F4] min-h-[100vh] w-full">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'Home'} />
      </div>
      <div className="flex-grow w-full">{children}</div>
    </div>
  );
};

export default AdminWrapper;
