import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { IProgressSpinnerProps } from './progress-spinner.types';

const ProgressSpinner = ({
  progress,
  progressColor,
}: IProgressSpinnerProps) => {
  return (
    <div className="w-12 h-12">
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles({
          pathColor: progressColor,
          textColor: progressColor,
          textSize: '1.6rem',
        })}
      />
    </div>
  );
};

export default ProgressSpinner;
