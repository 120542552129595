import React, { useState } from 'react';
import Dropdown from 'src/components/custom-dropdown/custom-dropdown';

const SmartDoc = () => {
    const [useLLM, setUseLLM] = useState('Yes');

    const options = ["Yes", "No"];

    return (
        <>
            <div className="p-1 bg-gray-100 min-h-screen">
                <div className="max-w-3xl bg-white shadow-md rounded-lg p-6">
                    <label className="block text-md font-medium mb-2">Use LLM</label>
                    <Dropdown 
                        options={options} 
                        selectedOption={useLLM} 
                        onOptionSelect={setUseLLM} 
                    />
                </div>
            </div>
        </>
    );
};

export default SmartDoc;
