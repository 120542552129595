import { useGetProjectDetailQuery } from 'src/redux/api-slice';
import ProjectSideBar from '../../../src/components/project-sidebar/project-sidebar';
import StagesComponent from './card/stages-component/stages-component';
import ProjectArtifacts from './project-artifacts/project-artifacts';
import ProjectOverviewTile from './project-overview-tile/project-overview-tile';
import ProjectStatus from './project-status/project-status';
import { useState } from 'react';
import Swal from 'sweetalert2';

const artifacts = [
  {
    id: 1,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
  {
    id: 2,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
  {
    id: 3,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
  {
    id: 4,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
];

const ProjectOverview = () => {
  const { isLoading, error } = useGetProjectDetailQuery({ projectId: '1' });
  const [alertsShown, setAlertsShown] = useState({ projectDetail: false });

  if (error && !alertsShown.projectDetail) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load project details. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, projectDetail: true });
  }

  return (
    <div className="h-[100vh]  flex flex-row bg-whiteSmoothLight w-full relative gap-4">
      <div className="mx-4 my-4 mb-0 pb-0 mr-0 h-[100vh] w-8">
        <ProjectSideBar activeScreen={'Home'} />
      </div>
      <div className="w-full h-[90vh] overflow-scroll mx-4 my-4 px-4 pb-8">
        <div>
          <h3 className="text-2xl font-bold my-4">Project Overview</h3>
          <p>
            Review the key details and progress of your project. Track the
            transformation steps, upload necessary artifacts, and monitor your
            journey from COBOL to Java.
          </p>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div className="flex justify-start mx-0 my-8 gap-6">
              <ProjectOverviewTile />
              <StagesComponent />
            </div>
            <div>
              <ProjectStatus />
            </div>
            <div className="mt-8 bg-white p-6 py-8 mb-8 rounded-md shadow-md">
              <ProjectArtifacts rows={artifacts} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProjectOverview;
