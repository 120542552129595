export const VALID_PROGRAM_FILE_TYPES = [
    'cbl',
    'cpy',
    'dcl',
    'list',
    'LIST',
    'lst',
    'LST',
    'txt',
    'TXT',
  ];