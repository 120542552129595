import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { IProjectDependencyProps } from './project-dependency.types';
import { Badge } from 'src/components/ui/badge';
import { FaProjectDiagram, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { setActiveModal } from 'src/redux/app-global';
import { EAppModal } from 'src/redux/app-global/app-global.types';
import { useState } from 'react';
import { Graphviz } from 'graphviz-react';
import DependencyGraphModal from '../project-dependency-graph/project-dependency-graph';


const ProjectDependency = ({
  projectDependencies,
}: IProjectDependencyProps) => {
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const dispatch = useDispatch();
  const activeModal = useSelector(
    (state: RootState) => state.appGlobal.activeModal,
  );

  const handleShowProjectDependency = (project: any) => {
    setSelectedProject(project);
    dispatch(setActiveModal(EAppModal.DEPENDENCY_GRAPH));
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
    dispatch(setActiveModal(null));
  };


  return (
    <div>
      <h1 className="text-2xl font-bold mb-2">Project Dependency</h1>
      <p className="text-lg text-gray-600 max-w-[80%]">
        Explore the relationships between your projects. Identify which projects
        rely on others to ensure smooth transformations and manage dependencies
        effectively.
      </p>
      <div className="mt-8">
        <Table>
          <TableCaption>A list of your project dependencies.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Id</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Type</TableHead>
              <TableHead className="text-right">Status</TableHead>
              <TableHead className="text-right">Dependency</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {projectDependencies.map((project: any) => (
              <TableRow key={project.id}>
                <TableCell className="font-medium">{project.id}</TableCell>
                <TableCell>{project.name}</TableCell>
                <TableCell>{project.type}</TableCell>
                <TableCell className="text-right">
                  <Badge variant="default">{project.status}</Badge>
                </TableCell>
                <TableCell className="text-right">
                  <button
                    onClick={() => handleShowProjectDependency(project)}
                    className="flex items-center justify-end ml-auto text-md text-blue-500 bg-transparent border border-blue-500 rounded-md py-2 px-4 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white hover:shadow-lg"
                  >
                    <FaProjectDiagram className="mr-2" />
                    View Dependency
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <DependencyGraphModal
        isOpen={activeModal === EAppModal.DEPENDENCY_GRAPH && !!selectedProject}
        onClose={handleCloseModal}
      />
    </div>
  );
};


export default ProjectDependency;
