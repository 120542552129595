import { FaTimes } from 'react-icons/fa';
import { Graphviz } from 'graphviz-react';
import { DependencyGraphProps } from './dependency-graph.types';
import { Badge } from 'src/components/ui/badge';

const DependencyGraph = ({ isOpen, onClose }: DependencyGraphProps) => {
  if (!isOpen) return null;

  const projectData = [
    { key: 'Project Name', value: 'Accounts Payable' },
    { key: 'Project Type', value: 'Smart Doc' },
    { key: 'Status', value: 'Active' },
  ];

  const dependencyGraph = `
  digraph G {
    "Project 1" [label=<Project 1<br/><font point-size="10">(Transformation)</font>>];
    "Project 2" [label=<Project 2<br/><font point-size="10">(Transformation)</font>>];
    "Project 3" [label=<Project 3<br/><font point-size="10">(Readiness)</font>>];
    "Project 4" [label=<Project 4<br/><font point-size="10">(Development)</font>>];
    "Project 5" [label=<Project 5<br/><font point-size="10">(Testing)</font>>];
    "Project 6" [label=<Project 6<br/><font point-size="10">(Testing)</font>>];
    "Project 7" [label=<Project 7<br/><font point-size="10">(Testing)</font>>];
    
    "Project 1" -> "Project 2";
    "Project 1" -> "Project 6";
    "Project 1" -> "Project 7";
    "Project 1" -> "Project 3";
    "Project 2" -> "Project 3";
    "Project 3" -> "Project 4";
    "Project 4" -> "Project 5";
    "Project 5" -> "Project 1";
  }
`;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-2xl shadow-2xl max-w-3xl w-auto h-[80vh] overflow-y-auto relative">
        <div className="absolute top-4 right-4">
          <button
            onClick={onClose}
            className="flex items-center justify-center w-10 h-10 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
            aria-label="Close modal"
          >
            <FaTimes size={20} />
          </button>
        </div>
        <div className="p-4">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Project Dependency Graph
          </h1>
          <div className="p-4 grid grid-cols-2 gap-6 bg-gray-50 rounded-lg shadow-inner">
            {projectData.map((item, index) => (
              <div key={index} className="flex items-center">
                <strong className="w-40 text-gray-700">{item.key}:</strong>
                {item.key === 'Status' ? (
                  <Badge color="green">{item.value}</Badge>
                ) : (
                  <span className="text-gray-600">{item.value}</span>
                )}
              </div>
            ))}
          </div>

          <div className="mt-6 p-4 border rounded-lg bg-gray-100 w-auto shadow-md h-auto flex justify-center items-center">
            <div className="w-full h-full" style={{ backgroundColor: 'white' }}>
              <Graphviz
                dot={dependencyGraph}
                options={{
                  width: '100%',
                  height: '18rem',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DependencyGraph;
