import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './pages/home/home';
import { SharedService } from './services/shared-service';
import Dashboard from './pages/dashboard/dashboard';
import ProjectOverview from './pages/project-overview/project-overview';
import NotesPage from './pages/notes/notes';
import ProjectTransformationSettings from './pages/project-transformationSettings/project-transformationSettings';
import LoginScreen from './pages/login-screen/login-screen';
import { useEffect, useId, useLayoutEffect } from 'react';
import ProjectReportPage from './pages/project-report-page/project-report-page';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import SupportTicketsPage from './pages/support-tickets/support-tickets';
import ReportsPage from './pages/admin/reports/reports';
import AdminNotesPage from './pages/admin/notes/notes';
import GlobalSettingsPage from './pages/admin/global-project-settings/global-project-settings';
import UsersPage from './pages/admin/users/users';
import DraftsProjectPage from './pages/admin/drafts/drafts';

const Wrapper = ({ children }) => {
  const id = useId();

  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById(id)?.scrollTo({ top: 0 });
    }, 100);
  }, []);
  return (
    <div id={id} className="overflow-y-auto h-full">
      {children}
    </div>
  );
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const userLoginDetails = useSelector(
    (state: RootState) => state.appGlobal.userLoginDetails,
  );

  if (!userLoginDetails) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return children;
};

export enum ROUTES {
  HOME = '/project-transform',
  DASHBOARD = '/dashboard',
  NOTES = '/notes',
  PROJECT_OVERVIEW = '/project-overview',
  PROJECT_TRANSFROMATION_SETTINGS = '/project-settings',
  PROJECT_REPORT = '/project-report',
  LOGIN = '/',
  SUPPORT_TICKETS = '/support-tickets',
  ADMIN_REPORTS = '/admin/reports',
  ADMIN_NOTES = '/admin/notes',
  ADMIN_GLOBAL_PROJECT_SETTINGS = '/admin/global-project-settings',
  USERS = '/admin/users',
  DRAFT_PROJECTS = '/admin/draft-projects',
}

const onPremRoutes = [
  {
    path: ROUTES.HOME,
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.DASHBOARD,
    element: (
      <Wrapper>
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.PROJECT_OVERVIEW,
    element: (
      <Wrapper>
        <PrivateRoute>
          <ProjectOverview />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.NOTES,
    element: (
      <Wrapper>
        <PrivateRoute>
          <NotesPage />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.PROJECT_TRANSFROMATION_SETTINGS,
    element: (
      <Wrapper>
        <PrivateRoute>
          <ProjectTransformationSettings />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.PROJECT_REPORT,
    element: (
      <Wrapper>
        <PrivateRoute>
          <ProjectReportPage />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.SUPPORT_TICKETS,
    element: (
      <Wrapper>
        <PrivateRoute>
          <SupportTicketsPage />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.LOGIN,
    element: (
      <Wrapper>
        <LoginScreen />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.ADMIN_REPORTS,
    element: (
      <Wrapper>
        <ReportsPage />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.ADMIN_NOTES,
    element: (
      <Wrapper>
        <AdminNotesPage />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.ADMIN_GLOBAL_PROJECT_SETTINGS,
    element: (
      <Wrapper>
        <GlobalSettingsPage />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.USERS,
    element: (
      <Wrapper>
        <UsersPage />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.DRAFT_PROJECTS,
    element: (
      <Wrapper>
        <DraftsProjectPage />
      </Wrapper>
    ),
  },
];

export default function AppNavigation() {
  const router = createBrowserRouter(
    SharedService.isOnPrem
      ? onPremRoutes
      : [
          {
            path: '*',
            element: (
              <Wrapper>
                <Home />
              </Wrapper>
            ),
          },
        ],
  );

  SharedService.router = router;

  return <RouterProvider router={router} />;
}
