
export interface ExtractDataSet {
    jcl?: string[];
    proc?: string[];
    metadata?: string[];
    cc?: string[];
    listing?: string[];
    ispf?: string[];
  }
  
  export enum ExtractType {
    JCL = 'JCL',
    PROC = 'PROC',
    METADATA = 'METADATA',
    CC = 'CC',
    LISTING = 'LISTING',
    ISPF = 'ISPF',
    ALL = 'ALL',
  }
  
  export interface ExtractJclRequest {
    dataset: ExtractDataSet;
    downloadFor: ExtractType;
    ispfCode?:string[];
  }
  