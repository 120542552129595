import { INoteCardProps } from 'src/components/notes-card/notes-card.types';

export const getProjectNotes = async (
  projectId: string,
): Promise<INoteCardProps[]> => {
  return [
    {
      id: '1',
      title: 'Initial Code Analysis',
      authorName: 'John Doe',
      content:
        'Reviewed COBOL codebase. Some modules need restructuring before conversion.',
      projectNames: 'Accounts Project',
      projectType: 'Smart Doc',
      date: '2021-08-01',
    },
    {
      id: '2',
      title: 'Artifact Upload Checklist',
      authorName: 'John Doe',
      content:
        'Ensure all required files are uploaded before starting the transformation.',
      projectNames: 'Accounts Project',
      projectType: 'Smart Doc',
      date: '2021-08-01',
    },
    {
      id: '3',
      title: 'Dependency Clarification',
      authorName: 'John Doe',
      content:
        'Confirmed that Module A depends on Module B for data processing.',
      projectNames: 'Accounts Project',
      projectType: 'Smart Doc',
      date: '2021-08-01',
    },
    {
      id: '4',
      title: 'Transformation Step Issues',
      authorName: 'John Doe',
      content:
        'Encountered a bug in Step 3. Need to revisit the transformation logic.',
      projectNames: 'Accounts Project',
      projectType: 'Smart Doc',
      date: '2021-08-01',
    },
    {
      id: '5',
      title: 'JIRA Ticket Follow-up',
      authorName: 'John Doe',
      content:
        'Pending response on JIRA ticket #123 regarding database migration.',
      projectNames: 'Accounts Project',
      projectType: 'Smart Doc',
      date: '2021-08-01',
    },
    {
      id: '6',
      title: 'Final Review Meeting',
      authorName: 'John Doe',
      content:
        'Scheduled review meeting for project deliverables on Friday at 10 AM.',
      projectNames: 'Accounts Project',
      projectType: 'Smart Doc',
      date: '2021-08-01',
    },
  ];
};
