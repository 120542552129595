export enum EAppModal {
  LEAD_INFORMATION = 'LEAD_INFORMATION',
  ASSISTED_SERVICE = 'ASSISTED_SERVICE',
  ACCESS_FULL_REPORT = 'ACCESS_FULL_REPORT',
  FILE_SIZE_LIMIT_EXCEEDED = 'FILE_SIZE_LIMIT_EXCEEDED',
  SCANNING_COMPLETED = 'SCANNING_COMPLETED',
  SCANNING_FAILED = 'SCANNING_FAILED',
  FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED',
  FILE_VALIDATION_EXCEEDED = 'FILE_VALIDATION_EXCEEDED',
  GENERIC_ALERT_MODAL = 'GENERIC_ALERT_MODAL',
  SCAN_JCL_FAILED = 'SCAN_JCL_FAILED',
  FEEDBACK = 'FEEDBACK',
  SCHEDULE_CALENDER = 'SCHEDULE_CALENDER',
  APP_VERSION = 'APP_VERSION',
  MISSING_ARTIFACTS = 'MISSING_ARTIFACTS',
  DOCUMENTATION_MODAL = 'DOCUMENTATION_MODAL',
  DATASET_DOWNLOAD = 'DATASET_DOWNLOAD',
  SUPPORT_TICKET = 'SUPPORT_TICKET',
  CALL_CHAIN_MODAL = 'CALL_CHAIN_MODAL',
  MISSING_ARTIFACT_ALERT = 'MISSING_ARTIFACT_ALERT',
  ABOUT_US = 'ABOUT_US',
  PROJECT_DETAIL_NOTE_WIDGET = 'PROJECT_DETAIL_NOTE_WIDGET',
  DEPENDENCY_GRAPH = 'DEPENDENCY_GRAPH',
}

export enum EAppIntent {
  HOME = 'HOME',
  ABOUT_US = 'ABOUT_US',
  ARTIFACT_UPLOAD = 'ARTIFACT_UPLOAD',
  ARTIFACT_INFO = 'ARTIFACT_INFO',
  ARTIFACT_SCAN = 'ARTIFACT_SCAN',
  REPORT = 'REPORT',
  CALLCHAIN = 'CALLCHAIN',
  UPLOADING_ARTIFACT = 'UPLOADING_ARTIFACT',
  SELF_SERVICE = 'SELF_SERVICE',
  COBOL_TO_JAVA = 'COBOL_TO_JAVA',
  CF_SERVICES = 'CF_SERVICES',
  SAMPLE_REPORT = 'SAMPLE_REPORT',
  CODE_ACCURACY = 'CODE_ACCURACY',
  EXTRACT_ARTIFACT = 'EXTRACT_ARTIFACT',
  JCL_ARTIFACT_INSTRUMENTATION = 'JCL_ARTIFACT_INSTRUMENTATION',
  JCL_CC_ARTIFACT_INSTRUMENTATION = 'JCL_CC_ARTIFACT_INSTRUMENTATION',
  JCL_PROC_ARTIFACT_INSTRUMENTATION = 'JCL_PROC_ARTIFACT_INSTRUMENTATION',
  LISTING_ARTIFACT_INSTRUMENTATION = 'LISTING_ARTIFACT_INSTRUMENTATION',
  TRANSFORMATION_PAGE = 'TRANSFORMATION_PAGE',
  MISSING_PROC = 'MISSING_PROC',
  MISSING_METADATA = 'MISSING_METADATA',
  MISSING_CONTROLCARD = 'MISSING_CONTROLCARD',
  MISSING_PROGRAM = 'MISSING_PROGRAM',
  MISSING_ARTIFACTS = 'MISSING_ARTIFACTS',
  RESOLVE_DYNAMIC_CALLS = 'RESOLVE_DYNAMIC_CALLS',
  TRANSFORM_SETTINGS = 'TRANSFORM_SETTINGS',
  TRANSFORMATION_REPORT = 'TRANSFORMATION_REPORT',

}

interface IUserDetails {
  name: string;
  companyEmail: string;
  phoneNumber: string;
  hearAboutUs?: string;
}

export interface MissingPage {
  key: EAppIntent;
  status: boolean;
  data: any;
}
export interface IUploadedArtifacts {
  artifacts: any;
  missingFiles: string[] | null;
  dependentFiles: string[] | null;
  zipFileStatus: Record<string, any>;
  fileStatus: Record<string, any>;
}
export interface ILicenseDetails {
  fileUploadLimit: number;
  licenseType: 'SAAS' | 'ONPREM';
  enableCdn?: string;
  licenseFor: string;
}
export interface TableData {
  programName: any;
  key: number;
  name: string;
  requiredBy: string[];
  uploaded: any;
  status?: string;
  selectedOption?: string;
  recordLength?: string;
  recordFormat?: string;
  clusterName?: string;
}
interface FileItem {
  file: File;
  name: string;
  size: number;
  type: string;
}

interface ISupportDetails {
  title: string;
  description: string;
  files: FileItem[];
}


export interface IAppGlobalState {
  activeModal: EAppModal | null;
  currentIntent: EAppIntent | null;
  currentContent: any;
  customChatMessage: any[];
  resetChatbot: boolean;
  scanCompleted: boolean;
  formSubmitted: boolean;
  emailSent: boolean;
  userDetails: IUserDetails | null;
  scannerErrorMessage?: string;
  uploadedArtifacts?: IUploadedArtifacts;
  scanJclFailed?: boolean;
  isCallChainLoaded?: boolean;
  scanProgress?: number;
  missingPrograms?: string[];
  missingUtilities?: string[];
  license: ILicenseDetails | null;
  reportData?: any;
  supportDetails: ISupportDetails | null;
  artifactStatus: Record<string, EArtifactStatus>;
  transformationProgress?: Record<string, number>;
  artifactTransformProfile: Record<string, string>;
  artifactDownloadAsService: Record<string, string>;
  nextMissingPage?: EAppIntent;
  supportTicketsGenerated?: { id: string; title: string }[];
  missingPages: MissingPage[];
  uploadedPrograms?: IUploadedArtifacts;
  uploadedControlCards?: IUploadedArtifacts;
  uploadedMissingArtifacts?: IUploadedArtifacts;
  uploadedMetadata?: IUploadedArtifacts;
  procData?: TableData[];
  programData?: TableData[];
  controlCardData?: TableData[];
  dynamicCallData?:TableData[];
  userLoginDetails?: IUuserLoginDetails | null;
  jclData?: Artifact[];
  transformationStatus?: Record<string, ITransformationStatus>;
}

export interface ITransformationStatus {
  PACKAGING: string;
  CODE_TRANSFORMATION: string;
  COMPILATION: string;
  CODE_GENERATION: string;
}

export enum EArtifactStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  INPROGRESS = 'inprogress',
  DEFAULT = 'default',
}

export interface TableData {
  key: number;
  name: string;
  requiredBy: string[];
  uploaded: any;
  status?: string;
  selectedOption?: string;
  recordLength?: string;
  recordFormat?: string;
  clusterName?: string;
}

// export interface Artifact {
//   name: string;
//   variableName: string;
//   programName: string;
//   value: string;
// }

export interface Artifact {
  value: any;
  name: string;
  referredIn: {
    jcl: string[];
    proc: string[];
  };
  symbolName: string;
  requiredBy: string;
  symbolValue: string;
}

// Dashboard & Tile Interface

export interface Tile {
  title: string;
  value: string | number;
  icon: string;
  bgColor?: string;
  dropdownOptions: string[];
  percentageRate: string;
  iconColor?: string;
  percentageColor?: string;
}

export interface DashboardState {
  tiles: Tile[];
  welcomeMessage: {
    title: string;
    description: string;
  };
  loading: boolean;
  error: string | null;
}

export interface IUuserLoginDetails {
  userId: string;
  role: string;
}
