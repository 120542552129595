import React from 'react';
import TransformSettings from '../project-settings-page/transformation';
import SmartDoc from '../project-settings-page/smart-doc';
import ApplicationBuilder from '../project-settings-page/application-builder';
import { TabProps } from './header-navigation.type';

const HeaderNavigation: React.FC<TabProps> = ({ activeTab, setActiveTab }) => {
  const tabs = [{ name: 'Transformation' }, { name: 'Application Builder' }];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Transformation':
        return <TransformSettings />;
      case 'Smart Doc':
        return <SmartDoc />;
      case 'Application Builder':
        return <ApplicationBuilder />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex space-x-4 mb-6 justify-left">
        {tabs.map(tab => (
          <button
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            className={`py-2 px-4 rounded-full ${
              activeTab === tab.name
                ? 'bg-black text-white'
                : 'bg-gray-200 text-black'
            }`}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {renderTabContent()}
    </>
  );
};

export default HeaderNavigation;
