import { useUser } from 'src/hooks/useUser';
import { IUserOnlyProps } from './user-only.types';

const UserOnly = ({ children }: IUserOnlyProps) => {
  const user = useUser();

  if (user?.role === 'Member') {
    return <div>{children}</div>;
  }

  return null;
};

export default UserOnly;
