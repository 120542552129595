// ReusableTable component with configurable headers and row data containing JSX elements

import React from 'react';
import HelpTextCallout from '../help-text-callout/help-text-callout';

interface ReusableTableProps {
    items: any[];
    headers: React.ReactNode[];
    rowFields: (item: any) => React.ReactNode[];
    helpTexts?: any;
}

const ReusableTable: React.FC<ReusableTableProps> = ({ items, headers, rowFields, helpTexts }) => {
    return (
        <table className="min-w-full divide-y divide-gray-200 py-4 mb-6 z-10 border rounded-lg shadow">
            <thead className="bg-gray-50">
                <tr>
                    {headers.map((header, index) => (
                        <th key={index} className="px-6 py-3 uppercase tracking-wider">
                            <div className='flex flex-row gap-2 items-center'>                                
                                <div className='text-left text-xs font-medium text-gray-500'>{header}</div>
                                {header && <HelpTextCallout calloutText={helpTexts[index].description}/>} 
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 py-4">
                {items?.map((item, index) => (
                    <tr key={index}>
                        {rowFields(item).map((field, idx) => (
                            <td key={idx} className="px-6 py-4 whitespace-nowrap text-sm">
                                {field}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ReusableTable;