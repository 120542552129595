import UserOnly from 'src/components/user-only/user-only';

const ProjectStatus = () => {
  return (
    <div className=" mt-8 flex justify-start items-center bg-blue-800 shadow-lg rounded-md p-6 border border-blue-700">
      <div className="w-[40%]">
        <p className="text-l text-white mb-4">
          <strong>Lines Used</strong>
        </p>
        <div className="h-2 w-full md:w-[70%] bg-gray-300 rounded-full mt-6">
          <div className="h-2 bg-black rounded-full" style={{ width: '20%' }} />
        </div>

        <p className="text-l text-white text-center mb-2 mt-4 ml-[35%]">
          <strong>20%</strong>
        </p>
      </div>

      <div className="flex-1 text-left text-white space-y-4 mt-2">
        <p className="text-l text-white">
          <strong>Assigned Limit:</strong> 100000 Lines
        </p>
        <p className="text-l text-white">
          <strong>Current Usage:</strong> 20000 Lines
        </p>
      </div>
      <div className="justify-self-end ml-auto ">
        <UserOnly>
          <button
            className="bg-white text-blue-800 py-4 px-6 rounded-lg 
        font-semibold text-md"
          >
            Notify Admin
          </button>
        </UserOnly>
      </div>
    </div>
  );
};

export default ProjectStatus;
