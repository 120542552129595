import { FiBell, FiX } from "react-icons/fi";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../components/ui/sheet";
import { useState } from "react";

const NotificationWidget = () => {
  const [notifications, setNotifications] = useState([
    { id: 1, message: "@Rajeev added a comment to Project 1", date: "Yesterday", color: "text-gray-500" },
    { id: 2, message: "Upcoming: Date of Project 2", date: "Today", color: "text-green-500" },
    { id: 3, message: "Running out of Usage Lines in Project 2. Notify Planner @Raphael", date: "Tomorrow", color: "text-yellow-500" },
  ]);

  const handleClose = (id) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  return (
    <div className="grid">
      <Sheet>
        <SheetTrigger asChild>
          <div className="relative flex items-center cursor-pointer">
            <FiBell size={30} className="text-white" />
            <div className="mx-4 h-8 w-px bg-gray-400" />
          </div>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Notifications</SheetTitle>
          </SheetHeader><br/>
          <div className="flex-1 space-y-2">
            {notifications.map(notification => (
              <div key={notification.id} className="flex items-start space-x-2 p-3 border-b hover:bg-gray-50 transition-all duration-150 ease-in-out cursor-pointer rounded-md">
                <div className="flex-shrink-0 p-2 rounded-full">
                  <FiBell className={notification.color} />
                </div>
                <div className="flex-1">
                  <p className="text-sm text-gray-800">
                    <strong className="text-gray-900">{notification.message}</strong>
                  </p>
                  <p className="text-xs text-gray-500">{notification.date}</p>
                </div>
                <button onClick={() => handleClose(notification.id)} className="text-gray-500 hover:text-gray-900">
                  <FiX size={20} />
                </button>
              </div>
            ))}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default NotificationWidget;
