import { useState } from 'react';
import { FaUserGroup } from 'react-icons/fa6';
import { RiAdminFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { setUserLoginDetails } from 'src/redux/app-global';
import { IUuserLoginDetails } from 'src/redux/app-global/app-global.types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('Admin');
  const [loginDetails, setLoginDetails] = useState<IUuserLoginDetails>({
    userId: 'John Doe',
    role: 'Admin',
  });
  const dispatch = useDispatch();
  const [password, setPassword] = useState('asd');

  const handleUserIdChange = event => {
    const value = event.target.value;
    setLoginDetails(prevDetails => ({
      ...prevDetails,
      userId: value,
    }));
  };

  const handleRoleChange = (role: string) => {
    setActiveButton(role);
    setLoginDetails(prevDetails => ({
      ...prevDetails,
      role: role,
    }));
  };

  const handlePasswordChange = event => {
    const value = event.target.value;
    setPassword(value);
  };
  const handleLogin = () => {
    if (loginDetails.userId === '' || password === '' || activeButton === '') {
      window.alert('Please Enter the required credentials to login.');
      return;
    }
    dispatch(setUserLoginDetails(loginDetails));
    navigate(ROUTES.DASHBOARD);
  };
  return (
    <div
      className="bg-whiteSmoothLight w-full flex items-center justify-center py-4"
      style={{ minHeight: 'calc(100vh - 100px)' }}
    >
      <div className="w-1/3 h-3/4 shadow-md rounded-md bg-white px-4 py-4">
        <h2 className="font-bold text-2xl text-center">Login</h2>
        <div className="py-4 flex flex-col gap-8">
          <div>
            <h3 className="text-l font-semibold mb-4">Username</h3>
            <input
              type="text"
              placeholder="Username"
              className="border-b-2 border-blue-400 w-full focus:border-cta focus:outline-none"
              value={loginDetails.userId}
              onChange={handleUserIdChange}
            />
          </div>
          <div className="flex flex-col">
            <h3 className="text-l font-semibold mb-4">Password</h3>
            <input
              type="password"
              placeholder="Password"
              className="border-b-2 border-blue-400 w-full focus:border-cta focus:outline-none"
              value={password}
              onChange={handlePasswordChange}
            />
            <button className="text-cta mt-4 text-left w-1/4 text-sm">
              Forgot Password?
            </button>
          </div>
          <div>
            <h3 className="text-l font-semibold mb-4">Select Role</h3>
            <div className="flex flex-row gap-4">
              <button
                className={`flex items-center justify-center px-4 py-4 bg-blue-100 w-1/4 text-cta rounded-md shadow-md ${activeButton === 'Admin' ? 'border-2 border-blue-500' : ''}`}
                onClick={() => handleRoleChange('Admin')}
              >
                <RiAdminFill className="mr-2" /> Admin
              </button>
              <button
                className={`flex items-center justify-center px-4 py-4 bg-blue-100 w-1/4 text-cta rounded-md shadow-md ${activeButton === 'Member' ? 'border-2 border-blue-500' : ''}`}
                onClick={() => handleRoleChange('Member')}
              >
                <FaUserGroup className="mr-2" />
                Member
              </button>
            </div>
          </div>
          <div>
            <button
              className="text-white bg-cta w-full py-2 hover:bg-blue-500"
              onClick={handleLogin}
            >
              Login
            </button>
            <div className="flex justify-center items-center mt-2">
              <p className="text-sm mr-2">Dont have an account?</p>
              <button className="text-cta text-sm">Contact us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginScreen;
