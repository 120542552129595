import { IDashboardTileProps } from './dashboard-tile.types';

const DashboardTile: React.FC<IDashboardTileProps> = ({
  title,
  value,
  icon,
  bgColor,
  dropdownOptions,
  percentageRate,
  iconColor,
  percentageColor,
}) => {
  return (
    <div
      className={`flex flex-col justify-between items-start p-4 px-6 rounded-lg shadow-md text-white h-auto w-auto ${bgColor || 'bg-blue-500'}`}
    >
      <div className="flex items-center justify-between w-full mb-4">
        <div className={`text-2xl ${iconColor}`}>{icon}</div>
        <select className="text-black p-2 rounded-lg bg-transparent focus:outline-none">
          {dropdownOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full">
        <div className="flex justify-between items-center mb-4">
          <h5 className="text-4xl font-semibold">{value}</h5>
        </div>
        <div className="flex items-center justify-between">
          <h2 className="text-l font-semibold">{title}</h2>
          <span className={`text-lg ml-2 ${percentageColor}`}>
            {percentageRate}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardTile;
