import axios from 'axios';
import { downloadBase64FromResponse, externalConfig } from '../../../utils/misc.utils';
import { SESSION_HEADER } from '../../../constants/app.constant';

export const downloadLog = async () => {
  try {
    const res = await axios.get(
      externalConfig.REACT_APP_API_URL + '/download/scan-log',
    );
    downloadBase64FromResponse(res);
  } catch (err) {
    console.log(err);
  }
};

export const downloadCode = async (name: string) => {
  try {
    const sessionId = sessionStorage.getItem(SESSION_HEADER) as string;
    const response = await fetch(
      externalConfig.REACT_APP_API_URL + '/download/generated-code/' + name,
      {
        method: 'GET',
        headers: {
          'X-CF-SESSIONID': sessionId,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.arrayBuffer();
    const blob = new Blob([data]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name + '.zip';
    link.click();
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
  }
};

export const startTransformation = async (
  names: { name: string; service: boolean }[],
  type: string,
) => {
  const res = await axios.post(
    externalConfig.REACT_APP_API_URL + '/transform/start',
    {
      artifactsInfo: names,
      type,
    },
  );
  return res.data;
};

export const stopTransformation = async (
  names: { name: string; service: boolean }[],
  type: string,
) => {
  const res = await axios.post(
    externalConfig.REACT_APP_API_URL + '/transform/stop',
    {
      artifactsInfo: names,
      type,
    },
  );
  return res.data;
};

export const downloadErrorLog = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_API_URL}/download/scan-log`,
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
