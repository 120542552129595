export interface IProjectApiObject {
  id: number;
  type: string;
  dueDate: string;
  startDate: string;
  progress: number;
  title: string;
  subTitle: string;
  status: string;
  description: string[];
}

export const getProjectDetails = async (): Promise<IProjectApiObject[]> => {
  return [
    {
      id: 1,
      type: 'Smart Doc',
      dueDate: '2024-12-09',
      startDate: '2024-08-03',
      progress: 40,
      title: 'Accounts Payable',
      subTitle: 'SMART DOC',
      status: 'ACTIVE',
      description: ['Stage Number - Current Stage Name', '3 Members'],
    },
    {
      id: 2,
      type: 'Optimizer',
      dueDate: '2024-12-10',
      startDate: '2024-04-03',
      progress: 20,
      title: 'Accounts Payable 2',
      subTitle: 'SMART DOC',
      status: 'ACTIVE',
      description: ['Stage Number - Current Stage Name', '3 Members'],
    },
    {
      id: 3,
      type: 'Transformation',
      dueDate: '2024-09-09',
      startDate: '2024-08-03',
      progress: 100,
      title: 'Accounts Payable 3',
      subTitle: 'SMART DOC',
      status: 'ACTIVE',
      description: ['Stage Number - Current Stage Name', '3 Members'],
    },
    {
      id: 4,
      type: 'Application Builder',
      dueDate: '2025-03-09',
      startDate: '2024-08-03',
      progress: 80,
      title: 'Accounts Payable 4',
      subTitle: 'SMART DOC',
      status: 'ACTIVE',
      description: ['Stage Number - Current Stage Name', '3 Members'],
    },
    {
      id: 5,
      type: 'Smart Doc',
      dueDate: '2025-12-09',
      startDate: '2024-09-03',
      progress: 100,
      title: 'Accounts Payable 5',
      subTitle: 'SMART DOC',
      status: 'ARCHIVED',
      description: ['Stage Number - Current Stage Name', '3 Members'],
    },
  ];
};

export const getDraftDetails = async (): Promise<IProjectApiObject[]> => {
  return [
    {
      id: 3,
      type: 'Transformation',
      dueDate: '2024-09-09',
      startDate: '2024-08-03',
      progress: 0,
      title: 'Accounts Payable 10',
      subTitle: 'SMART DOC',
      status: 'DRAFT',
      description: ['Stage Number - Extract', '0 Members'],
    },
    {
      id: 4,
      type: 'Application Builder',
      dueDate: '2025-03-09',
      startDate: '2024-08-03',
      progress: 0,
      title: 'Accounts Payable 9',
      subTitle: 'SMART DOC',
      status: 'DRAFT',
      description: ['Stage Number - Extract', '0 Members'],
    },
  ];
};

export const getProjectInvites = async () => {
  try {
    return [
      {
        id: 1,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Number - Current Stage Name', '3 Members'],
      },
      {
        id: 2,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Number - Current Stage Name', '3 Members'],
      },
      {
        id: 3,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Number - Current Stage Name', '3 Members'],
      },
      {
        id: 4,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Number - Current Stage Name', '3 Members'],
      },
    ];
  } catch (err) {
    console.log('Something went wrong', err);
  }
};

export const getProjectDependencies = async () => {
  return [
    {
      id: 1,
      name: 'Project 1',
      type: 'Smart Doc',
      status: 'Active',
      description: 'Project is on hold due to license limit',
    },
    {
      id: 2,
      name: 'Project 2',
      type: 'Optimizer',
      status: 'Active',
      description: 'Dependency on Stage 3 of Project 1',
    },
    {
      id: 3,
      name: 'Project 3',
      type: 'Transformation',
      status: 'Active',
      description: 'Dependency on Stage 2 of Project 2',
    },
    {
      id: 4,
      name: 'Project 4',
      type: 'Application Builder',
      status: 'Active',
      description: 'Dependency on Stage 1 of Project 3',
    },
    {
      id: 5,
      name: 'Project 5',
      type: 'Smart Doc',
      status: 'Active',
      description: 'Dependency on Stage 3 of Project 4',
    },
  ];
};
