import { IProjectTileProps } from './project-tile.types';

const ProjectTile = ({
  title,
  subTitle,
  description,
  topLeftIcon,
  topRightContent,
  actionCta,
  className,
  onClick,
}: IProjectTileProps) => {
  return (
    <div
      className={`tile p-6 shadow-md rounded-2xl h-max w-auto flex flex-col justify-between bg-white ${className}`}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          {topLeftIcon}
          <div className="mb-2">
            <p className="text-xl font-bold mb-1">{title}</p>
            <p className="text-l mb-2 text-grey80">{subTitle}</p>
          </div>
        </div>
        <div className="cursor-pointer">{topRightContent}</div>
      </div>
      <div className="my-4 flex flex-col gap-1">
        {description.map(text => (
          <p key={text} className="text-l text-gray-600">
            {text}
          </p>
        ))}
      </div>
      <div className="ml-auto">{actionCta}</div>
    </div>
  );
};

export default ProjectTile;
