import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import NotesFloatingButton from '../notes-floating-button/notes-floating-button';
import { useState } from 'react';

const Widget = () => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setSuccess(false);

    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
    }, 2000);
  };

  return (
    <div className="p-4">
      <div>
        <h1 className="text-md font-semibold mb-2">Add Note</h1>
      </div>
      <div className="w-full h-[1px] bg-greyCardOutline my-2" />
      <div
        style={{
          marginBottom: '.5rem',
          lineHeight: '1.5',
          color: '#374151',
        }}
      >
        <label
          htmlFor="email"
          style={{
            fontSize: '0.8rem',
            lineHeight: '1.75',
            color: '#4a5568',
            marginBottom: 0,
          }}
        >
          Subject *
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={subject}
          placeholder="Subject"
          onChange={e => setSubject(e.target.value)}
          style={{
            width: '100%',
            borderRadius: '0',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            padding: '0.5rem 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#333',
            outline: 'none',
            transition: 'color 0.2s ease-in-out',
            height: 'unset',
            fontWeight: '400',
          }}
        />
      </div>
      <div
        style={{
          marginBottom: '.5rem',
          marginTop: '.5rem',
        }}
      >
        <label
          htmlFor="message"
          style={{
            fontSize: '0.8rem',
            lineHeight: '1.75',
            color: '#4a5568',
            marginBottom: 0,
          }}
        >
          Content *
        </label>
        <textarea
          id="content"
          name="content"
          placeholder="Add caption..."
          value={content}
          onChange={e => setContent(e.target.value)}
          style={{
            height: '7rem',
            width: '100%',
            borderRadius: '0',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            padding: '0.5rem 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#333',
            outline: 'none',
            transition: 'color 0.2s ease-in-out',
            fontWeight: '400',
          }}
        ></textarea>
      </div>
      <button
        type="submit"
        onClick={handleSubmit}
        disabled={!subject || !content || loading}
        style={{
          borderRadius: 4,
          backgroundColor: 'rgb(19,98,223)',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          fontSize: '.9rem',
          color: '#fff',
          borderWidth: 0,
          opacity: !subject || !content ? 0.5 : 1,
        }}
      >
        {loading ? 'Loading...' : 'Add Note'}
      </button>
    </div>
  );
};

const AddNotesWidget: React.FC = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <NotesFloatingButton onClick={() => {}} />
      </PopoverTrigger>
      <PopoverContent className="m-4 mb-2 mr-8 bg-gray-50">
        <Widget />
      </PopoverContent>
    </Popover>
  );
};

export default AddNotesWidget;
