export const getProjectDetail = async (projectId: string) => {
  return {
    id: 1,
    type: 'Smart Doc',
    dueDate: '2024-12-09',
    startDate: '2024-08-03',
    progress: 40,
    title: 'Accounts Payable',
    subTitle: 'SMART DOC',
    status: 'ACTIVE',
    description: ['Stage Number - Current Stage Name', '3 Members'],
  };
};
