import { useState } from 'react';
import SvgLeftArrow from '../../assets/images/svg/left-arrow.svg';
import { appTheme } from '../../styles/theme';
import { colorLegendData } from '../../hooks/call-chain.service';
import HelpTextCallout from '../help-text-callout/help-text-callout';
import { helpTexts } from '../../helpTexts';

const appColors = appTheme.colors;

const GraphLegend = ({
  usedColors,
  inModal = false,
}: {
  usedColors: string[];
  inModal?: boolean;
}) => {
  const [showLegend, setShowLegend] = useState(true);
  const pageHelpText = helpTexts[0].CALL_CHAIN;
  return (
    <div
      className="absolute bg-white top-[12rem] right-4 p-2"
      style={{
        background: '#F9F9FA',
        right: '0rem',
        top: '0.3rem',
      }}
    >
      <div
        className="relative cursor-pointer"
        onClick={() => setShowLegend(!showLegend)}
      >
        <img
          src={SvgLeftArrow}
          alt="slider"
          className=" absolute -left-[2rem] top-[4.85rem]"
          style={{
            transform: showLegend ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </div>
      {showLegend && (
        <>
       <div className="flex items-center space-x-2">
       <HelpTextCallout calloutText={pageHelpText[1].description} />
       <h4 className="text-md font-semibold">Program Color Codes</h4>
     </div>
     <hr className="my-2 border-grey" />
     </>
      )}
      <div className="text-sm pl-2 flex flex-col gap-4 min-h-[6rem]">
        {colorLegendData
          .filter(legend => usedColors.includes(legend.color))
          .map(legend => (
            <div
              key={legend.color}
              className="flex gap-2 justify-start items-center"
            >
              <div
                style={{ background: legend.color }}
                className="h-[1rem] w-[1rem] rounded-full"
              />{' '}
              <span className={`${showLegend ? '' : 'hidden'}`}>
                {legend.label}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default GraphLegend;
